import addFormToDataLayer from './addFormToDataLayer'

/**
 * Report form analytics event to Adobe Analytics
 * @param {String} action Action the form performs
 * @param {Bool} hasSentAnalytics Have the analytics been reported
 * @param {Bool} isResubmitAllowed Are allowed to resubmit form
 * @param {String} status Status of form submission
 */
const reportFormAdobeAnalyticsEvent = ({
  action,
  hasSentAnalytics,
  isResubmitAllowed = false,
  status
}) => {
  if (!status) return

  if ((!hasSentAnalytics || isResubmitAllowed) && status === 'succeeded') {
    addFormToDataLayer({ action })
  } else if (status === 'failed') {
    addFormToDataLayer({ action, type: 'error' })
  }
}

export default reportFormAdobeAnalyticsEvent
