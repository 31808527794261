import { createSelector } from '@reduxjs/toolkit'
import isArrayWithLength from '~utils/isArrayWithLength'

const EMPTY_OBJECT = {}
const EMPTY_ARRAY = []

export const selectAppInitialized = state => state.accounts?.appInitialized
export const selectAuthStatus = state =>
  state.accounts?.authStatus || EMPTY_OBJECT
export const selectAutocomplete = state =>
  state.accounts?.autocomplete || EMPTY_OBJECT
export const selectBillingInfoStatus = state =>
  state.accounts?.billingInfoStatus
export const selectEmailPreferences = state => state.accounts?.emailPreferences
export const selectIsGoogleSsoUser = state => state.accounts?.isGoogleSsoUser
export const selectIsLoggedIn = state => state.accounts?.isLoggedIn
export const selectRoleName = state =>
  state.accounts?.userProfile?.data?.roleName
export const selectRoleNameStatus = state => state.accounts?.roleNameStatus
export const selectUserEmail = state => state.accounts?.email || EMPTY_OBJECT
export const selectUserProfile = state =>
  state.accounts?.userProfile || EMPTY_OBJECT
export const selectWordList = state => state.accounts?.wordList || EMPTY_OBJECT
export const selectWordLists = state => state.accounts?.wordLists || EMPTY_ARRAY
export const selectWordListStatus = state =>
  state.accounts?.wordListStatus || EMPTY_OBJECT

export const selectIsSlugSavedInAWordList = createSelector(
  selectWordLists,
  (_, slug) => slug,
  (wordLists, slug) => {
    const hasWords =
      isArrayWithLength(wordLists) &&
      wordLists.some(wordList => wordList.words !== undefined)

    return (
      hasWords &&
      !!wordLists
        .flatMap(wordList => wordList.words)
        ?.find(word => word.slug === slug)
    )
  }
)
