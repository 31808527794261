// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Tt8va4k2wJ9jHFvWBojK{columns:2;list-style:none;padding-left:0}.Tt8va4k2wJ9jHFvWBojK li{margin:8px auto}@media(min-width: 768px){.Tt8va4k2wJ9jHFvWBojK{columns:3}}`, "",{"version":3,"sources":["webpack://./src/client/components/composites/IdiomList/IdiomList.module.scss"],"names":[],"mappings":"AAAA,sBACE,SAAA,CACA,eAAA,CACA,cAAA,CAEA,yBACE,eAAA,CAIJ,yBACE,sBACE,SAAA,CAAA","sourcesContent":[".root {\n  columns: 2;\n  list-style: none;\n  padding-left: 0;\n\n  li {\n    margin: 8px auto;\n  }\n}\n\n@media (min-width: 768px) {\n  .root {\n    columns: 3;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `Tt8va4k2wJ9jHFvWBojK`
};
module.exports = ___CSS_LOADER_EXPORT___;
