import React, { useEffect, useRef, useState } from 'react'
import cx from 'classnames'
import withStyles from 'isomorphic-style-loader/withStyles'
import { useDispatch, useSelector } from 'react-redux'
import useOnClickOutside from '~hooks/useOnClickOutside'
import {
  fetchWordList,
  getAutocompleteResults,
  upsertToWordList
} from '~store/accounts/actions'
import {
  selectAutocomplete,
  selectWordList,
  selectWordListStatus
} from '~store/accounts/selectors'
import { removeDuplicatesByKey } from '~utils/removeDuplicatesByKey'
import styles from './WordListSearchbar.module.scss'

const AUTOCOMPLETE_RESULTS_LIMIT = 9

const WordListSearchbar = () => {
  const dispatch = useDispatch()
  const autocomplete = useSelector(selectAutocomplete)
  const { shortId, words } = useSelector(selectWordList)
  const { status, type } = useSelector(selectWordListStatus)
  const [inputVal, setInputVal] = useState('')
  const [isFocused, setIsFocused] = useState(false)
  const [activeIndex, setActiveIndex] = useState(null)
  const inputRef = useRef()
  useOnClickOutside(inputRef, () => setIsFocused(false))

  useEffect(() => {
    if (status === 'succeeded' && type === 'upsertToWordList') {
      dispatch(fetchWordList({ shortId }))
    }
  }, [status, type])

  const handleChange = ({ target: { value } }) => {
    setInputVal(value)
    setActiveIndex(null)
  }

  const handleKeyUp = () => {
    dispatch(getAutocompleteResults(inputVal))
  }

  const handleFocus = () => {
    setIsFocused(true)
  }

  const handleResultClick = (slug, displayForm) => () => {
    setInputVal('')
    dispatch(upsertToWordList({ displayForm, shortId, slug, source: 'dcom' }))
  }

  const filteredResults = removeDuplicatesByKey(
    autocomplete?.data,
    'displayForm'
  )
    ?.filter(({ slug }) => !words.find(word => word.slug === slug))
    .slice(0, AUTOCOMPLETE_RESULTS_LIMIT)

  const onInputKeyDown = ({ key }) => {
    if (!isFocused || !filteredResults) return

    const currentResultsLength = filteredResults.length

    if (key === 'ArrowDown') {
      const nextResultIndex =
        activeIndex !== null && activeIndex < currentResultsLength - 1
          ? activeIndex + 1
          : 0
      setActiveIndex(nextResultIndex)
    } else if (key === 'ArrowUp') {
      const previousResultIndex =
        activeIndex < 1 ? currentResultsLength - 1 : activeIndex - 1
      setActiveIndex(previousResultIndex)
    } else if (key === 'Enter') {
      setInputVal('')
      if (filteredResults.length < 1) return
      const selectedResultIndex = activeIndex !== null ? activeIndex : 0
      const { displayForm, slug } = filteredResults[selectedResultIndex]
      handleResultClick(slug, displayForm)()
      setActiveIndex(null)
    } else if (key === 'Tab') {
      setIsFocused(false)
    }
  }

  return (
    <div className={styles.root} data-type="word-list-searchbar">
      <div className={styles['input-container']}>
        <span />
        <input
          onChange={handleChange}
          onFocus={handleFocus}
          onKeyDown={onInputKeyDown}
          onKeyUp={handleKeyUp}
          placeholder="Type a word to add it"
          ref={inputRef}
          value={inputVal}
        />
      </div>
      {isFocused && filteredResults?.length > 0 && (
        <ul className={styles.results}>
          {filteredResults.map(({ displayForm, slug }, index) => {
            const listItemClassNames = cx({
              [styles.highlighted]: index === activeIndex
            })

            return (
              <li
                className={listItemClassNames}
                key={slug}
                onClick={handleResultClick(slug, displayForm)}
              >
                {displayForm}
              </li>
            )
          })}
        </ul>
      )}
    </div>
  )
}

export default withStyles(styles)(WordListSearchbar)
