// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nAMMOmmcor9ux4C79a4F{align-items:center;background:none #fff;border:2px solid var(--dictionaryblue);border-radius:100px;color:var(--dictionaryblue);cursor:pointer;display:flex;font-size:16px;font-weight:bold;line-height:1.5;padding:4px 16px;text-decoration:none}.nAMMOmmcor9ux4C79a4F:hover:not([disabled]){transform:scale(1.05)}.fAb7x5rxJtoKMH7OE2op{transform:rotate(90deg)}`, "",{"version":3,"sources":["webpack://./src/client/components/composites/CompareMoreWordsButton/CompareMoreWordsButton.module.scss"],"names":[],"mappings":"AAAA,sBACE,kBAAA,CACA,oBAAA,CACA,sCAAA,CACA,mBAAA,CACA,2BAAA,CACA,cAAA,CACA,YAAA,CACA,cAAA,CACA,gBAAA,CACA,eAAA,CACA,gBAAA,CACA,oBAAA,CAEA,4CACE,qBAAA,CAIJ,sBACE,uBAAA","sourcesContent":[".root {\n  align-items: center;\n  background: none #fff;\n  border: 2px solid var(--dictionaryblue);\n  border-radius: 100px;\n  color: var(--dictionaryblue);\n  cursor: pointer;\n  display: flex;\n  font-size: 16px;\n  font-weight: bold;\n  line-height: 1.5;\n  padding: 4px 16px;\n  text-decoration: none;\n\n  &:hover:not([disabled]) {\n    transform: scale(1.05);\n  }\n}\n\n.icon {\n  transform: rotate(90deg);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `nAMMOmmcor9ux4C79a4F`,
	"icon": `fAb7x5rxJtoKMH7OE2op`
};
module.exports = ___CSS_LOADER_EXPORT___;
