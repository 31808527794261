import React from 'react'
import { arrayOf, func, number, shape, string } from 'prop-types'
import cx from 'classnames'
import withStyles from 'isomorphic-style-loader/withStyles'
import {
  DICTIONARY_URL,
  SearchResultSources,
  THESAURUS_URL
} from '~client/constants'
import { A } from '~elements'
import { escapeRegExpString } from '~utils/string'
import styles from './SearchResultsSection.module.scss'

export const SearchResultsSection = ({
  dictionaryUrl,
  listItems,
  onClick,
  searchValue,
  sectionName,
  selectedResultIndex,
  setSelectedResultIndex,
  startList,
  thesaurusUrl,
  updateInput
}) => {
  const { name } = listItems[0].reference.source
  const isDefinitionsContent = name === SearchResultSources.DICTIONARY
  const isSynonymsContent = name === SearchResultSources.THESAURUS

  const searchResultsSectionClassNames = cx(styles['search-results-section'], {
    [styles.definitions]: isDefinitionsContent,
    [styles.synonyms]: isSynonymsContent
  })

  const displayResultText = resultText => {
    if (!searchValue) return resultText

    const escapedSearchValue = escapeRegExpString(searchValue)
    const matchSearchValue = new RegExp(
      `(.*)(${escapedSearchValue})\b?(.*)`,
      'i'
    )
    const matchResults = resultText.match(matchSearchValue)
    if (!matchResults) return resultText

    const [, textBefore, searchTerm, textAfter] = matchResults

    return (
      <>
        {textBefore && <span className={styles.autofill}>{textBefore}</span>}
        {searchTerm}
        {textAfter && <span className={styles.autofill}>{textAfter}</span>}
      </>
    )
  }

  return (
    <>
      <li className={searchResultsSectionClassNames}>{sectionName}</li>
      <ul>
        {listItems.map(
          (
            {
              displayText,
              reference: {
                source: { name, redirectUrlPath },
                type: badgeContent
              }
            },
            index
          ) => {
            const updatedIndex = index + startList + 1

            const highlightResultClassNames = cx(
              styles['search-results-item'],
              {
                [styles['search-results-item-active']]:
                  updatedIndex === selectedResultIndex,
                [styles['search-results-item-no-hover']]:
                  updatedIndex !== selectedResultIndex
              }
            )

            const isDictionaryEditorialContent = name.includes(
              SearchResultSources.DICTIONARY_EDITORIAL
            )
            const isSynonymsContent = name === SearchResultSources.THESAURUS
            const isThesaurusEditorialContent = name.includes(
              SearchResultSources.THESAURUS_EDITORIAL
            )

            const siteUrl =
              isSynonymsContent || isThesaurusEditorialContent
                ? thesaurusUrl
                : dictionaryUrl
            const redirectUrl = `${siteUrl}${redirectUrlPath}`

            return (
              <li key={displayText}>
                <A
                  className={highlightResultClassNames}
                  href={redirectUrl}
                  id={updatedIndex}
                  onMouseDown={onClick.bind(this, index)}
                  onMouseEnter={() => {
                    setSelectedResultIndex(updatedIndex)
                    updateInput(updatedIndex)
                  }}
                  onMouseLeave={() => setSelectedResultIndex(0)}
                >
                  {displayResultText(displayText)}
                  {(isDictionaryEditorialContent ||
                    isThesaurusEditorialContent) && (
                    <span className={styles.badge}>{badgeContent}</span>
                  )}
                </A>
              </li>
            )
          }
        )}
      </ul>
    </>
  )
}

SearchResultsSection.propTypes = {
  dictionaryUrl: string,
  listItems: arrayOf(
    shape({
      displayText: string,
      reference: shape({
        source: shape({
          name: string,
          redirectUrl: string
        }),
        type: string
      })
    })
  ).isRequired,
  onClick: func.isRequired,
  searchValue: string,
  sectionName: string.isRequired,
  selectedResultIndex: number.isRequired,
  setSelectedResultIndex: func.isRequired,
  startList: number.isRequired,
  thesaurusUrl: string,
  updateInput: func.isRequired
}

SearchResultsSection.defaultProps = {
  dictionaryUrl: DICTIONARY_URL,
  searchValue: null,
  thesaurusUrl: THESAURUS_URL
}

export default withStyles(styles)(SearchResultsSection)
