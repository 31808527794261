import React, { useEffect, useRef } from 'react'
import { bool, object, string } from 'prop-types'
import withStyles from 'isomorphic-style-loader/withStyles'
import {
  DefinitionList,
  DefinitionSets,
  SeeMoreBlock,
  SeeMoreInline
} from '~composites'
import parseHtml from '~utils/parseHtml'
import styles from './Definitions.module.scss'

const MAX_DEFINITIONS = 2

const Definitions = ({
  block: useSeeMoreBlock,
  moduleType: moduleTypeName,
  posBlock,
  source
}) => {
  const { definitions, definitionSets, pos, posSupplementaryInfo } = posBlock
  const isScienceContent = source === 'science'
  const definitionListContent = isScienceContent ? definitionSets : definitions

  let hasMoreDefinitions
  if (isScienceContent) {
    hasMoreDefinitions = definitionSets?.length > MAX_DEFINITIONS
  } else {
    hasMoreDefinitions = definitions?.length > MAX_DEFINITIONS
  }

  const definitionList = isScienceContent ? (
    <DefinitionSets definitions={definitionListContent} />
  ) : (
    <DefinitionList definitions={definitionListContent} />
  )

  const definitionListContinued = isScienceContent ? (
    <DefinitionSets definitions={definitionListContent} isContinued />
  ) : (
    <DefinitionList definitions={definitionListContent} isContinued />
  )

  const posRef = useRef()

  useEffect(() => {
    // because this html comes from the api we have to modify it after the fact
    const playerContainer = posRef.current?.querySelector('.audio-player')
    const audioElement = posRef.current?.querySelector('audio')
    if (playerContainer) {
      playerContainer.onclick = () => {
        audioElement.play()
      }
    }
  }, [posRef.current])

  return (
    <div className={styles.root} data-type="word-definitions">
      {!pos.match('Idioms') && (
        <span className={styles.pos}>{parseHtml(pos)}</span>
      )}
      {posSupplementaryInfo && (
        <span ref={posRef}>{parseHtml(posSupplementaryInfo)}</span>
      )}
      {definitionList}
      {hasMoreDefinitions && !useSeeMoreBlock && (
        <SeeMoreInline className={styles['see-more-inline']}>
          {definitionListContinued}
        </SeeMoreInline>
      )}
      {hasMoreDefinitions && useSeeMoreBlock && (
        <SeeMoreBlock
          buttonClosedText={`See More ${moduleTypeName}`}
          buttonOpenText={`See Fewer ${moduleTypeName}`}
          className={styles['see-more-block']}
        >
          {definitionListContinued}
        </SeeMoreBlock>
      )}
    </div>
  )
}

Definitions.propTypes = {
  block: bool,
  moduleType: string,
  posBlock: object.isRequired,
  source: string.isRequired
}

Definitions.defaultProps = {
  block: false,
  moduleType: 'Definitions'
}

export default withStyles(styles)(Definitions)
