import React from 'react'
import withStyles from 'isomorphic-style-loader/withStyles'
import { useSelector } from 'react-redux'
import { A } from '~elements'
import { selectWordOfTheDay } from '~store/wordpress/selectors'
import isObjectWithProperties from '~utils/isObjectWithProperties'
import styles from './BuildYourWordList.module.scss'

const BuildYourWordList = () => {
  const { results } = useSelector(selectWordOfTheDay)

  if (!isObjectWithProperties(results)) return null

  const key = Object.keys(results)[0]
  const { word } = results[key]

  return (
    <div className={styles.root}>
      <h3>Build Your Word List!</h3>
      <p>
        Today&apos;s Word of the Day is <strong>{word}</strong>. Read the
        definition and select the <span className={styles.icon} /> at the top of
        the page to add the word to your list.
      </p>
      <A href={`/browse/${word}`}>Get Started</A>
    </div>
  )
}

export default withStyles(styles)(BuildYourWordList)
