/**
 * Sort lists by key in provided order
 * @param {Object[]} list Array of objects of the same type
 * @param {String} key A property of the object
 * @param {Bool} isAscending Is in ascending order
 */
const sortByKey = (list, key, isAscending = true) => {
  return [...list].sort((first, second) => {
    const firstKey = first[key].toLowerCase()
    const secondKey = second[key].toLowerCase()

    if (firstKey > secondKey) {
      return isAscending ? 1 : -1
    } else if (firstKey < secondKey) {
      return isAscending ? -1 : 1
    }
    return 0
  })
}

/**
 * Sort lists by key in provided order without changing the strings to lower/upper case
 * @param {Object[]} list Array of objects of the same type
 * @param {String} key A property of the object
 * @param {Bool} isAscending Is in ascending order
 * @returns
 */
export const sortByKeyWithoutChangingCase = (list, key, isAscending = true) => {
  return [...list].sort((first, second) => {
    const firstKey = first[key]
    const secondKey = second[key]

    if (firstKey > secondKey) {
      return isAscending ? 1 : -1
    } else if (firstKey < secondKey) {
      return isAscending ? -1 : 1
    }
    return 0
  })
}

export default sortByKey
