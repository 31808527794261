// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gaQlShzMaRTHCNns3GYB{font-size:18px;line-height:1.5}.gaQlShzMaRTHCNns3GYB ol{list-style:none;margin:0;padding-left:38px}.gaQlShzMaRTHCNns3GYB ol p{margin:0}.gaQlShzMaRTHCNns3GYB olli{padding:0 0 8px 8px}.gaQlShzMaRTHCNns3GYB li::marker{font-size:14px}.gaQlShzMaRTHCNns3GYB ul{list-style:lower-alpha;padding-left:20px}.gaQlShzMaRTHCNns3GYB ul p{margin:0}`, "",{"version":3,"sources":["webpack://./src/client/components/composites/DefinitionSets/DefinitionSets.module.scss"],"names":[],"mappings":"AAAA,sBACE,cAAA,CACA,eAAA,CAEA,yBACE,eAAA,CACA,QAAA,CACA,iBAAA,CAEA,2BACE,QAAA,CAGF,2BACE,mBAAA,CAIJ,iCACE,cAAA,CAGF,yBACE,sBAAA,CACA,iBAAA,CAEA,2BACE,QAAA","sourcesContent":[".root {\n  font-size: 18px;\n  line-height: 1.5;\n\n  ol {\n    list-style: none;\n    margin: 0;\n    padding-left: 38px;\n\n    p {\n      margin: 0;\n    }\n\n    &li {\n      padding: 0 0 8px 8px;\n    }\n  }\n\n  li::marker {\n    font-size: 14px;\n  }\n\n  ul {\n    list-style: lower-alpha;\n    padding-left: 20px;\n\n    p {\n      margin: 0;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `gaQlShzMaRTHCNns3GYB`
};
module.exports = ___CSS_LOADER_EXPORT___;
