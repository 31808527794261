// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.la8GZ0ALbazH11eQGUwV{font-size:15px;line-height:22px}.la8GZ0ALbazH11eQGUwV a{color:var(--dictionaryblue);text-decoration:none}`, "",{"version":3,"sources":["webpack://./src/client/components/composites/CulturalDefinitionContent/CulturalDefinitionContent.module.scss"],"names":[],"mappings":"AAAA,sBACE,cAAA,CACA,gBAAA,CAEA,wBACE,2BAAA,CACA,oBAAA","sourcesContent":[".root {\n  font-size: 15px;\n  line-height: 22px;\n\n  a {\n    color: var(--dictionaryblue);\n    text-decoration: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `la8GZ0ALbazH11eQGUwV`
};
module.exports = ___CSS_LOADER_EXPORT___;
