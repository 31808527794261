// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pdmh5gOdlqBkPPyE2EUL{margin-bottom:20px}`, "",{"version":3,"sources":["webpack://./src/client/components/composites/CulturalDefinitionCard/CulturalDefinitionCard.module.scss"],"names":[],"mappings":"AAAA,sBACE,kBAAA","sourcesContent":[".card {\n  margin-bottom: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": `pdmh5gOdlqBkPPyE2EUL`
};
module.exports = ___CSS_LOADER_EXPORT___;
