import reportFormAdobeAnalyticsEvent from '../adobe-analytics/reportFormAdobeAnalyticsEvent'
import reportFormDaisyAnalyticsEvent from './reportFormDaisyAnalyticsEvent'

/**
 * Report form analytics event to Adobe and Daisy Analytics
 * @param {String} action Action the form performs
 * @param {Object} event Event object
 * @param {Bool} hasSentAnalytics Have the analytics been reported
 * @param {Bool} isResubmitAllowed Are allowed to resubmit form
 * @param {String} linkIds Daisy analytics link ids
 * @param {String} status Status of form submission
 */
const reportFormAnalyticsEvent = ({
  action,
  event,
  hasSentAnalytics,
  isResubmitAllowed,
  linkIds,
  status
}) => {
  reportFormAdobeAnalyticsEvent({
    action,
    hasSentAnalytics,
    isResubmitAllowed,
    status
  })
  reportFormDaisyAnalyticsEvent({
    event,
    hasSentAnalytics,
    isResubmitAllowed,
    linkIds,
    status
  })
}

export default reportFormAnalyticsEvent
