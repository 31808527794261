import React from 'react'
import withStyles from 'isomorphic-style-loader/withStyles'
import { Button, Icon } from '~elements'
import rightArrowIcon from '~public/images/icons/right-arrow.svg'
import styles from './CompareMoreWordsButton.module.scss'

const TARGET_DATA_TYPE = 'compare-more-module'

const onClick = () => {
  const target = document.querySelector(
    `section[data-type="${TARGET_DATA_TYPE}"]`
  )
  target.scrollIntoView({ behavior: 'smooth' })
}

const CompareMoreWordsButton = () => {
  return (
    <Button
      className={styles.root}
      data-type="compare-more-words"
      onClick={onClick}
    >
      Compare More Words
      <Icon className={styles.icon} src={rightArrowIcon} />
    </Button>
  )
}

export default withStyles(styles)(CompareMoreWordsButton)
