import React, { useState } from 'react'
import { bool, string } from 'prop-types'
import cx from 'classnames'
import withStyles from 'isomorphic-style-loader/withStyles'
import { Button, DefaultLink } from '~elements'
import useDictionaryUrl from '~hooks/useDictionaryUrl'
import parseHtml from '~utils/parseHtml'
import {
  createPasswordErrors,
  passwordDefaultValidationText,
  validatePassword
} from '~utils/password'
import styles from './PasswordInput.module.scss'

const INPUT_NAME = 'password'

const PasswordInput = ({
  label,
  showForgotPassword,
  validate,
  validationPlaceholder
}) => {
  const { forgotPasswordUrl } = useDictionaryUrl()
  const [showPassword, setShowPassword] = useState(false)
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')
  const [success, setSuccess] = useState(false)

  const validateInput = ({ target: { value } }) => {
    setPassword(value)
    const isPasswordValid = validatePassword(value)
    const errors = createPasswordErrors(value)
    if (!value) {
      setError(
        validate ? passwordDefaultValidationText : 'Please enter a password.'
      )
    } else if ((!isPasswordValid && validate) || !value) {
      setError(errors[0])
    } else {
      setError('')
      setSuccess(true)
    }
  }

  const inputClassNames = cx(styles['input-container'], {
    [styles['validation-error']]: !!error
  })
  const validationClassnames = cx({
    [styles['validation-success']]: success && validate
  })

  const errorMessage = error && (
    <p className={styles['validation-error']}>{error}</p>
  )

  return (
    <div className={styles.root} data-type="password-input">
      <div className={styles['label-container']}>
        <label htmlFor={INPUT_NAME}>{label}</label>
        {showForgotPassword && (
          <DefaultLink
            data-analytics="aa-track"
            data-linklocation="body"
            data-linkmodule="form"
            data-linkname="forgot-password"
            data-linktype="link"
            data-linkurl={forgotPasswordUrl}
            href="/forgot-password"
            linkId="cprj16"
          >
            Forgot password?
          </DefaultLink>
        )}
      </div>
      <div className={inputClassNames}>
        <input
          autoCapitalize="none"
          autoComplete="on"
          autoCorrect="off"
          id={INPUT_NAME}
          onBlur={validateInput}
          onChange={validateInput}
          onKeyUp={validateInput}
          placeholder="Password"
          required
          type={showPassword ? 'text' : 'password'}
          value={password}
        />
        <Button
          id={styles['show-button']}
          onClick={() => {
            setShowPassword(!showPassword)
          }}
        >
          {showPassword ? 'HIDE' : 'SHOW'}
        </Button>
      </div>
      {error ? (
        errorMessage
      ) : (
        <p className={validationClassnames}>
          {parseHtml(validationPlaceholder)}
        </p>
      )}
    </div>
  )
}

PasswordInput.propTypes = {
  label: string,
  showForgotPassword: bool,
  validate: bool,
  validationPlaceholder: string
}

PasswordInput.defaultProps = {
  label: 'Password',
  showForgotPassword: false,
  validate: false,
  validationPlaceholder: null
}

export default withStyles(styles)(PasswordInput)
