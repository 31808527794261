import { capitalize } from '~utils/string'

/**
 * Report form analytics event to Adobe Analytics
 * @param {String} action Action the form performs
 * @param {String} type Type of event
 */
const addFormToDataLayer = ({ action, type = 'success' }) => {
  const eventType = type === 'success' ? 'Succeeded' : capitalize(type)

  window.appEventData = window.appEventData || []

  // Adobe requires a 2-step process: first submitting event data for it to see, followed by the "Form Submission X" event
  window.appEventData.push({
    event: `Form Submission Started ${eventType}`,
    form: {
      formAction: action,
      formName: `form:${action}`,
      formType: type
    }
  })

  window.appEventData.push({
    event: `Form Submission ${eventType}`
  })
}

export default addFormToDataLayer
