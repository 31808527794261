import React, { useEffect, useState } from 'react'
import withStyles from 'isomorphic-style-loader/withStyles'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { DICTIONARY_URL_TERMS, SignUpSources } from '~client/constants'
import { ErrorCard, GoogleSSOButton, PasswordInput } from '~composites'
import {
  Button,
  CheckBox,
  DefaultLink,
  Form,
  RedesignButton,
  TextInput
} from '~elements'
import useDictionaryUrl from '~hooks/useDictionaryUrl'
import { signUpUserWithRedirect } from '~store/accounts/actions'
import { selectAuthStatus } from '~store/accounts/selectors'
import { selectSiteName } from '~store/location/selectors'
import { DICTIONARY_SITENAME } from '~store/location/slice'
import { LINKID_SIGNUP } from '~utils/analytics/linkIds'
import reportFormAnalyticsEvent from '~utils/analytics/reportFormAnalyticsEvent'
import styles from './AccountSignUpForm.module.scss'

const PASSWORD_MESSAGE =
  'Use at least 8 characters. Include an uppercase letter, a lowercase letter, a number, and a special character.'

const AccountSignUpForm = () => {
  const { signInUrl } = useDictionaryUrl()
  const { search } = useLocation()
  const { status } = useSelector(selectAuthStatus)
  const siteName = useSelector(selectSiteName)
  const [formEvent, setFormEvent] = useState()
  const [hasSentAnalytics, setHasSentAnalytics] = useState(false)
  const [isValidForm, setIsValidForm] = useState(true)
  const dispatch = useDispatch()
  const isDictionarySite = siteName === DICTIONARY_SITENAME

  useEffect(() => {
    if (status === 'succeeded') {
      setHasSentAnalytics(true)
    }

    reportFormAnalyticsEvent({
      action: 'sign-up',
      formEvent,
      hasSentAnalytics,
      linkIds: LINKID_SIGNUP.continue,
      status
    })
  }, [status])

  const onSubmit = event => {
    event.preventDefault()

    const source = isDictionarySite
      ? SignUpSources.DICTIONARY
      : SignUpSources.THESAURUS

    const formData = {
      email: event.target.email.value,
      firstName: event.target.firstName.value.trim(),
      lastName: event.target.lastName.value.trim(),
      password: event.target.password.value,
      source,
      subscribe: event.target.subscribe.checked
    }

    const hasAllData = Object.values(formData).every(
      value => Boolean(value) || typeof value === 'boolean'
    )

    if (!hasAllData) {
      setIsValidForm(false)
      return
    }

    dispatch(signUpUserWithRedirect(formData))
    setFormEvent(event)
  }

  const forwardToSignIn = () => {
    window.location.href = `${signInUrl}${search}`
  }

  return (
    <div className={styles.root} data-type="account-sign-up-form">
      <div className={styles['intro-container']}>
        {status === 'failed' && (
          <ErrorCard message="An account with that email already exists." />
        )}
        <h3>Sign up for an account</h3>
      </div>
      <Form id="form" noValidate onSubmit={onSubmit}>
        <div className={styles.names}>
          <TextInput
            errorText="Please enter your first name."
            isValid={isValidForm}
            label="First Name"
            name="firstName"
            placeholder="First Name"
            type="text"
          />
          <TextInput
            errorText="Please enter your last name."
            isValid={isValidForm}
            label="Last Name"
            name="lastName"
            placeholder="Last Name"
            type="text"
          />
        </div>
        <TextInput
          errorText="Please enter a valid email address."
          isValid={isValidForm}
          label="Your Email"
          name="email"
          placeholder="yourname@email.com"
          type="email"
        />
        <PasswordInput
          placeholder="Password"
          validate
          validationPlaceholder={PASSWORD_MESSAGE}
        />
        <CheckBox
          checked
          label="Yes! I want to receive fun Dictionary.com emails."
          name="subscribe"
        />
        <p>
          By pressing the Sign Up button below, you agree to
          Dictionary.com&apos;s{' '}
          <DefaultLink href={DICTIONARY_URL_TERMS}>
            Terms of Service
          </DefaultLink>
          , including the arbitration provision. See also our{' '}
          <DefaultLink href={DICTIONARY_URL_TERMS}>Privacy Policy</DefaultLink>.
        </p>
        <RedesignButton className={styles['sign-up']} type="submit">
          Sign Up
        </RedesignButton>
        <div className={styles.separator}>
          <span>or</span>
        </div>
        <GoogleSSOButton />
        <Button
          className={styles['log-in']}
          data-analytics="aa-track"
          data-linklocation="body"
          data-linkmodule="form"
          data-linkname="log in"
          data-linktype="cta"
          data-linkurl={`${signInUrl}${search}`}
          linkId={LINKID_SIGNUP.signin}
          onClick={forwardToSignIn}
        >
          Already have an account? Log in
        </Button>
      </Form>
    </div>
  )
}

export default withStyles(styles)(AccountSignUpForm)
