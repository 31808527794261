import React, { useEffect, useState } from 'react'
import withStyles from 'isomorphic-style-loader/withStyles'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { ErrorCard, GoogleSSOButton, PasswordInput } from '~composites'
import { Button, Form, RedesignButton, TextInput } from '~elements'
import useDictionaryUrl from '~hooks/useDictionaryUrl'
import { signInUser } from '~store/accounts/actions'
import { selectAuthStatus } from '~store/accounts/selectors'
import { LINKID_SIGNIN } from '~utils/analytics/linkIds'
import reportFormAnalyticsEvent from '~utils/analytics/reportFormAnalyticsEvent'
import styles from './AccountSignInForm.module.scss'

const AccountSignInForm = () => {
  const { signUpUrl } = useDictionaryUrl()
  const { search } = useLocation()
  const [formEvent, setFormEvent] = useState()
  const [hasSentAnalytics, setHasSentAnalytics] = useState(false)
  const [isValidForm, setIsValidForm] = useState(true)
  const dispatch = useDispatch()
  const { status } = useSelector(selectAuthStatus)

  useEffect(() => {
    if (status === 'succeeded') {
      setHasSentAnalytics(true)
    }

    reportFormAnalyticsEvent({
      action: 'sign-in',
      formEvent,
      hasSentAnalytics,
      linkIds: LINKID_SIGNIN.continue,
      status
    })
  }, [status])

  const onSubmit = event => {
    event.preventDefault()

    const email = event.target.email.value
    const password = event.target.password.value

    const hasAllData = !!email && !!password

    if (!hasAllData) {
      setIsValidForm(false)
      return
    }

    dispatch(
      signInUser({
        email,
        password
      })
    )

    setFormEvent(event)
  }

  const forwardToSignUp = () => {
    window.location.href = `${signUpUrl}${search}`
  }

  return (
    <div className={styles.root} data-type="account-sign-in-form">
      <div className={styles['intro-container']}>
        {status === 'failed' && (
          <ErrorCard message="Incorrect email address or password." />
        )}
        <h3>Log in to your account</h3>
      </div>
      <Form id="form" noValidate onSubmit={onSubmit}>
        <TextInput
          errorText="Please enter a valid email address."
          isValid={isValidForm}
          label="Your Email"
          name="email"
          placeholder="yourname@email.com"
          type="email"
        />
        <PasswordInput
          placeholder="Password"
          showForgotPassword
          validationPlaceholder={
            '<b>Remember:&nbsp;</b>Passwords are case sensitive.'
          }
        />
        <RedesignButton type="submit">Log In</RedesignButton>
        <div className={styles.separator}>
          <span>or</span>
        </div>
        <Button
          className={styles['sign-up']}
          data-analytics="aa-track"
          data-linklocation="body"
          data-linkmodule="form"
          data-linkname="sign up"
          data-linktype="cta"
          data-linkurl={signUpUrl}
          linkId={LINKID_SIGNIN.signup}
          onClick={forwardToSignUp}
        >
          Sign Up with Email
        </Button>
        <GoogleSSOButton />
      </Form>
    </div>
  )
}

export default withStyles(styles)(AccountSignInForm)
