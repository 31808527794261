// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.yKlXDG3w80U84NJ5V8xR{font-size:18px;line-height:1.5}.yKlXDG3w80U84NJ5V8xR ol{margin:0;padding-left:38px}.yKlXDG3w80U84NJ5V8xR ol p{margin:0}.yKlXDG3w80U84NJ5V8xR ol li{padding:0 0 8px 8px}.yKlXDG3w80U84NJ5V8xR li::marker{font-size:14px}.yKlXDG3w80U84NJ5V8xR ul{list-style:lower-alpha;padding-left:20px}.yKlXDG3w80U84NJ5V8xR ul p{margin:0}aside.Iyr5ZVndXlvORUxmOVaK{align-items:center;display:flex;position:relative;right:23px}`, "",{"version":3,"sources":["webpack://./src/client/components/composites/DefinitionList/DefinitionList.module.scss"],"names":[],"mappings":"AAAA,sBACE,cAAA,CACA,eAAA,CAEA,yBACE,QAAA,CACA,iBAAA,CAEA,2BACE,QAAA,CAIJ,4BACE,mBAAA,CAGF,iCACE,cAAA,CAGF,yBACE,sBAAA,CACA,iBAAA,CAEA,2BACE,QAAA,CAKN,2BACE,kBAAA,CACA,YAAA,CACA,iBAAA,CACA,UAAA","sourcesContent":[".root {\n  font-size: 18px;\n  line-height: 1.5;\n\n  ol {\n    margin: 0;\n    padding-left: 38px;\n\n    p {\n      margin: 0;\n    }\n  }\n\n  ol li {\n    padding: 0 0 8px 8px;\n  }\n\n  li::marker {\n    font-size: 14px;\n  }\n\n  ul {\n    list-style: lower-alpha;\n    padding-left: 20px;\n\n    p {\n      margin: 0;\n    }\n  }\n}\n\naside.ad {\n  align-items: center;\n  display: flex;\n  position: relative;\n  right: 23px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `yKlXDG3w80U84NJ5V8xR`,
	"ad": `Iyr5ZVndXlvORUxmOVaK`
};
module.exports = ___CSS_LOADER_EXPORT___;
