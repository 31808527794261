import { reportClick, reportClickInner } from '~utils/analytics/reporting'

/**
 * Report form analytics event to Daisy Analytics
 * @param {Object} event Event object
 * @param {Bool} hasSentAnalytics Have the analytics been reported
 * @param {Bool} isResubmitAllowed Are allowed to resubmit form
 * @param {String} linkIds Daisy analytics link ids
 * @param {String} status Status of form submission
 */
const reportFormDaisyAnalyticsEvent = ({
  event,
  hasSentAnalytics,
  isResubmitAllowed = false,
  linkIds,
  status
}) => {
  if (!status) return

  if ((!hasSentAnalytics || isResubmitAllowed) && status === 'succeeded') {
    const isInnerTarget = linkIds.startsWith('ua_')

    /**
     * click - click takes to new page
     * click inner - click stays on same page
     */
    if (isInnerTarget) {
      reportClickInner(linkIds)
    } else {
      reportClick({
        linkIds,
        ...event
      })
    }
  }
}

export default reportFormDaisyAnalyticsEvent
