import { Auth } from '@aws-amplify/auth'
import withStyles from 'isomorphic-style-loader/withStyles'
import { useSearchParams } from 'react-router-dom'
import { Button, Icon } from '~elements'
import googleLogo from '~public/images/google-logo.svg'
import { clearCognitoCookies } from '~utils/auth'
import styles from './GoogleSSOButton.module.scss'

export const GoogleSSOButton = () => {
  const [searchParams] = useSearchParams()
  const redirectUrl = searchParams.get('redirect')

  const handleClick = () => {
    if (redirectUrl) {
      sessionStorage.setItem('redirectURL', redirectUrl)
    }
    clearCognitoCookies()
    Auth.federatedSignIn({
      provider: 'Google'
    })
  }

  return (
    <Button className={styles.root} onClick={handleClick}>
      <Icon src={googleLogo} />
      Continue with Google
    </Button>
  )
}

export default withStyles(styles)(GoogleSSOButton)
