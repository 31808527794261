import React, { useEffect, useState } from 'react'
import cx from 'classnames'
import withStyles from 'isomorphic-style-loader/withStyles'
import { useDispatch, useSelector } from 'react-redux'
import {
  EMAIL_VALIDATION_REGEX,
  SUPPORT_MAILING_ADDRESS
} from '~client/constants'
import { A, Form, TextInput } from '~elements'
import { recoverPassword } from '~store/accounts/actions'
import { selectAuthStatus } from '~store/accounts/selectors'
import { INNER_TARGET_RECOVER_PASSWORD } from '~utils/analytics/linkIds'
import reportFormAnalyticsEvent from '~utils/analytics/reportFormAnalyticsEvent'
import styles from './RecoverPasswordForm.module.scss'

const RecoverPasswordForm = () => {
  const [email, setEmail] = useState('')
  const [hasSentAnalytics, setHasSentAnalytics] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isResubmitAllowed, setIsResubmitAllowed] = useState(false)
  const [isValidForm, setIsValidForm] = useState(true)
  const { error, status } = useSelector(selectAuthStatus)
  const dispatch = useDispatch()

  const validateEmail = email => EMAIL_VALIDATION_REGEX.test(email)

  const buttonClassNames = cx({ [styles['is-loading']]: isLoading })

  const linkId = isResubmitAllowed
    ? INNER_TARGET_RECOVER_PASSWORD.resendLink
    : INNER_TARGET_RECOVER_PASSWORD.getLink

  useEffect(() => {
    if (status === 'succeeded') {
      setIsResubmitAllowed(true)
      setHasSentAnalytics(true)
    }

    reportFormAnalyticsEvent({
      action: 'forgot-password',
      hasSentAnalytics,
      isResubmitAllowed,
      linkIds: linkId,
      status
    })
  }, [status])

  const onSubmit = e => {
    e.preventDefault()

    if (isResubmitAllowed) {
      setIsLoading(true)
      setTimeout(() => {
        setIsLoading(false)
      }, 1000)
    }

    const newEmail = e.target.email.value
    setEmail(newEmail)

    const hasAllData = !!newEmail

    if (!hasAllData || !validateEmail(newEmail)) {
      setIsValidForm(false)
      return
    }

    dispatch(recoverPassword({ email: newEmail }))
  }

  const buttonIsLoadingContent = (
    <span>
      <span>&bull;</span>
      <span>&bull;</span>
      <span>&bull;</span>
    </span>
  )

  const buttonContent = isLoading
    ? buttonIsLoadingContent
    : 'Resend Recovery Link'

  const footer = (
    <div className={styles.footer}>
      <p>Need more help? Reach out to our team at</p>
      <A
        data-analytics="aa-track"
        data-linklocation="body"
        data-linkmodule="form"
        data-linkname={SUPPORT_MAILING_ADDRESS}
        data-linktype="link"
        data-linkurl={`mailto:${SUPPORT_MAILING_ADDRESS}`}
        href={`mailto:${SUPPORT_MAILING_ADDRESS}`}
      >
        {SUPPORT_MAILING_ADDRESS}
      </A>
    </div>
  )

  const resendEmail = (
    <Form noValidate onSubmit={onSubmit}>
      <div>
        <h3>Check Your Inbox</h3>
        <p>
          If an account exists, a password recovery link has been sent to{' '}
          <b>{email}</b>
        </p>
      </div>
      <input name="email" type="hidden" value={email} />
      <button className={buttonClassNames} disabled={isLoading} type="submit">
        {buttonContent}
      </button>
      {footer}
    </Form>
  )

  return (
    <div className={styles.root} data-type="recover-password">
      {status === 'failed' && (
        <div className={styles['error-container']}>{error?.message}</div>
      )}
      {isResubmitAllowed ? (
        resendEmail
      ) : (
        <>
          <div>
            <h3>Reset Password</h3>
            <p>
              Enter your email, and we will send you a link to reset your
              password.
            </p>
          </div>
          <Form id="form" noValidate onSubmit={onSubmit}>
            <TextInput
              errorText="Please enter a valid email address."
              isValid={isValidForm}
              label="Your Email"
              name="email"
              placeholder="yourname@email.com"
              type="email"
            />
            <button className={buttonClassNames} type="submit">
              Get Recovery Link
            </button>
            {footer}
          </Form>
        </>
      )}
    </div>
  )
}

export default withStyles(styles)(RecoverPasswordForm)
