import React from 'react'
import { bool, func } from 'prop-types'
import { CardElement } from '@recurly/react-recurly'
import cx from 'classnames'
import withStyles from 'isomorphic-style-loader/withStyles'
import amexLogo from '~public/images/amex-logo.svg'
import discoverLogo from '~public/images/discover-logo.svg'
import masterCardLogo from '~public/images/mastercard-logo.svg'
import visaLogo from '~public/images/visa-logo.svg'
import styles from './CreditCardInput.module.scss'

const creditCardLogos = [
  {
    alt: 'visa',
    src: visaLogo
  },
  {
    alt: 'master card',
    src: masterCardLogo
  },
  {
    alt: 'amex',
    src: amexLogo
  },
  {
    alt: 'discover',
    src: discoverLogo
  }
]

const CreditCardInput = ({ isValid, setIsValid }) => {
  const classNames = cx(styles.root, {
    [styles['validation-error-container']]: !isValid
  })

  const acceptedCreditCards = creditCardLogos.map(({ alt, src }) => (
    <img alt={alt} className={styles['credit-card']} key={alt} src={src} />
  ))

  const validateFields = ({ state: { empty, valid } }) => {
    setIsValid(!empty && valid)
  }

  return (
    <div className={classNames} data-type="credit-card-input">
      <label htmlFor="cardNumber">
        Card Information <span>{acceptedCreditCards}</span>
      </label>
      <CardElement
        className={styles['credit-card-input']}
        data-recurly="card"
        onBlur={validateFields}
        style={{
          color: '#767676',
          fontFamily: 'Arial, sans-serif',
          fontSize: '14px',
          placeholder: {
            color: '#b6b6b6'
          }
        }}
      />
      {!isValid && <p>Please enter your card number, expiration, and CVV.</p>}
    </div>
  )
}

CreditCardInput.propTypes = {
  isValid: bool.isRequired,
  setIsValid: func.isRequired
}

export default withStyles(styles)(CreditCardInput)
