import React from 'react'
import { string } from 'prop-types'
import withStyles from 'isomorphic-style-loader/withStyles'
import { useSelector } from 'react-redux'
import { Definitions } from '~composites'
import { ResultsCard } from '~elements'
import { selectResultsPageIdioms } from '~store/luna/selectors'
import styles from './IdiomsAbout.module.scss'

const IdiomsAbout = ({ word }) => {
  const idiomsData = useSelector(selectResultsPageIdioms)

  if (!idiomsData) return null

  return (
    <ResultsCard className={styles.root} data-type="idioms-about-word">
      <h2 className={styles.idioms}>Idioms about {word}</h2>
      <Definitions
        block
        moduleType="Idioms"
        posBlock={idiomsData}
        source="idioms"
      />
    </ResultsCard>
  )
}

IdiomsAbout.propTypes = {
  word: string.isRequired
}

export default withStyles(styles)(IdiomsAbout)
