import React, { useState } from 'react'
import { bool, string } from 'prop-types'
import cx from 'classnames'
import withStyles from 'isomorphic-style-loader/withStyles'
import { DICTIONARY_URL, THESAURUS_URL } from '~client/constants'
import { BarsIcon, Button, XIcon } from '~elements'
import styles from './MobileNavigationDrawer.module.scss'
import TopLevelMenuItems from './TopLevelMenuItems'

export const MobileNavigationDrawer = ({
  className,
  dictionaryUrl,
  isLoggedIn,
  selectedSite,
  shouldShowProfileCtas,
  thesaurusUrl
}) => {
  const [isOpen, setIsOpen] = useState(false)

  const classNames = cx(styles.root, className)
  const containerClassNames = cx(styles['drawer-container'], {
    [styles.open]: isOpen
  })
  const underlayClassNames = cx(styles['drawer-underlay'], {
    [styles.open]: isOpen
  })

  const openMenu = () => {
    setIsOpen(true)
  }

  const closeMenu = () => {
    setIsOpen(false)
  }

  return (
    <div className={classNames}>
      <Button className={styles['toggle-button']} onClick={openMenu}>
        <BarsIcon />
      </Button>
      <div className={containerClassNames}>
        <Button className={styles['close-button']} onClick={closeMenu}>
          <XIcon />
        </Button>
        <ul>
          <TopLevelMenuItems
            dictionaryUrl={dictionaryUrl}
            isLoggedIn={isLoggedIn}
            isOpen={isOpen}
            selectedSite={selectedSite}
            shouldShowProfileCtas={shouldShowProfileCtas}
            thesaurusUrl={thesaurusUrl}
          />
        </ul>
      </div>
      <div className={underlayClassNames} onClick={closeMenu} />
    </div>
  )
}

MobileNavigationDrawer.propTypes = {
  className: string,
  dictionaryUrl: string,
  isLoggedIn: bool,
  selectedSite: string.isRequired,
  shouldShowProfileCtas: bool,
  thesaurusUrl: string
}

MobileNavigationDrawer.defaultProps = {
  className: null,
  dictionaryUrl: DICTIONARY_URL,
  isLoggedIn: false,
  shouldShowProfileCtas: false,
  thesaurusUrl: THESAURUS_URL
}

export default withStyles(styles)(MobileNavigationDrawer)
