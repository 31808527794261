import React, { useCallback, useEffect, useRef, useState } from 'react'
import { string } from 'prop-types'
import cx from 'classnames'
import withStyles from 'isomorphic-style-loader/withStyles'
import fetch from 'isomorphic-unfetch'
import {
  DICTIONARY_SITENAME,
  THESAURUS_SITENAME,
  DICTIONARY_URL,
  PROPTYPE_SITENAME,
  SearchResultSources,
  THESAURUS_URL
} from '~client/constants'
import { Button, MagnifyingGlassIcon } from '~elements'
import useOnClickOutside from '~hooks/useOnClickOutside'
import addSearchToDataLayer from '~utils/adobe-analytics/addSearchToDataLayer'
import styles from './Search.module.scss'
import SearchResultsSection from './SearchResultsSection'

const MINIMUM_SEARCH_VALUE_LENGTH = 2
const CONTEXT_DICTIONARY_COM = 'dcom';
const CONTEXT_THESAURUS_COM = 'tcom';

const getUriText = searchComponent => {
  return decodeURIComponent(searchComponent).replace(/[^A-Za-z0-9]/g, '')
}

export const Search = ({
  className,
  dictionaryUrl,
  selectedSite,
  thesaurusUrl
}) => {
  const searchContainerRef = useRef()
  const searchInputRef = useRef()
  const [isSearchInitiated, setIsSearchInitiated] = useState(false)
  const [isSearching, setIsSearching] = useState(false)
  const [placeholderText, setPlaceholderText] = useState(
    'Type any word or phrase'
  )
  const [searchResults, setSearchResults] = useState([])
  const [searchResultsCount, setSearchResultsCount] = useState()
  const [searchValue, setSearchValue] = useState('')
  const [selectedResultIndex, setSelectedResultIndex] = useState(0)
  const [selectedResultValue, setSelectedResultValue] = useState('')
  const classNames = cx(styles.root, className)
  const totalResults = searchResults.length
  const hasSearchResults = totalResults > 0

  /**
   * In an effect so that it runs only on client side render.
   * Unless we pass an autofocus prop from the HeaderModule,
   * we are limited to window width, which is not available server-side.
   *
   * We are defaulting to NOT autofocus on the server render because
   * it's less disruptive to gain focus rather than lose it.
   */
  useEffect(() => {
    if (window.innerWidth >= 768) {
      setPlaceholderText('Start typing any word or phrase')
      searchInputRef.current?.focus()
    }
  }, [])

  const processAnalyticsSearchData = ({ href, index, keyType }) => {
    const displayCount = searchResults.length || null
    const resultPositionClick = index + 1

    const isHrefEditorial = href.includes('.com/e/')
    const isHrefThesaurus = href.includes('thesaurus.com')

    let termType
    if (isHrefEditorial) {
      termType = 'non-lexi'
    } else if (isHrefThesaurus) {
      termType = 'thes-com'
    } else {
      termType = 'dict-com'
    }

    const searchData = {
      clickedTerm: searchValue,
      displayCount,
      resultClickType: keyType,
      resultPositionClick,
      resultsCount: searchResultsCount,
      termType,
      termURL: href
    }

    addSearchToDataLayer({ action: 'searched', searchData })
    addSearchToDataLayer({ action: 'completed' })
  }

  const clearSearchResults = () => {
    setSearchResults([])
  }

  const abandonSearch = () => {
    if (hasSearchResults) {
      setTimeout(() => {
        clearSearchResults()
        addSearchToDataLayer({
          action: 'abandoned',
          searchData: { clickedTerm: searchValue }
        })
      }, 250)
    }
  }

  useOnClickOutside(searchContainerRef, abandonSearch)

  const onChange = ({ target: { value } }) => {
    setSearchValue(value)
    setSelectedResultValue('')
  }

  const onClick = (index, event) => {
    event.preventDefault()
    const { href } = event.currentTarget
    processAnalyticsSearchData({ href, index, keyType: 'Click' })
  }

  const onKeyDown = event => {
    const { key } = event
    if (key === 'Escape') {
      abandonSearch()
    } else if (key === 'Tab') {
      /**
       * setTimeout is necessary to get next focused element,
       * not element that's focused at time of tab press.
       */
      setTimeout(() => {
        const isActiveElementInsideSearchContainer =
          searchContainerRef.current.contains(document.activeElement)
        if (!isActiveElementInsideSearchContainer) {
          abandonSearch()
        }
      })
    } else if (key === 'ArrowDown') {
      updateSelectedResultValue(selectedResultIndex + 1)
      setSelectedResultIndex(increaseIndex)
    } else if (key === 'ArrowUp') {
      updateSelectedResultValue(selectedResultIndex - 1)
      setSelectedResultIndex(decreaseIndex)
    } else if (key === 'Enter') {
      if (selectedResultIndex > 0) {
        const listItemValue = document.getElementById(`${selectedResultIndex}`)
        listItemValue.focus()
        window.location.href = document.activeElement.href
      } else {
        onSubmit(event)
      }
    }
  }

  const increaseIndex = index => {
    const updatedIndex = index < totalResults ? index + 1 : 0
    if (updatedIndex === 0) {
      resetHighlightedResult()
    }
    return updatedIndex
  }

  const decreaseIndex = index => {
    const updatedIndex = index > 1 ? index - 1 : 0
    if (updatedIndex === 0) {
      resetHighlightedResult()
    }
    return updatedIndex
  }

  const updateSelectedResultValue = resultIndex => {
    if (resultIndex > 0 && resultIndex <= totalResults) {
      const listItemValue = document.getElementById(`${resultIndex}`).innerText
      setSelectedResultValue(listItemValue)
    }
  }

  const resetHighlightedResult = () => {
    setSelectedResultIndex(0)
    setSelectedResultValue('')
  }

  const onSubmit = event => {
    event.preventDefault()
    if (!searchValue) return

    const keyType = event.key === 'Enter' ? 'Keypress' : 'Click'
    const firstResult = searchContainerRef.current.querySelector('a')
    const searchPath = firstResult?.pathname
    const isEditorialContent = searchPath?.includes('/e/')

    const parsedSearchValue = getUriText(searchValue)
    const parsedUriParam = getUriText(searchPath?.split('/browse/').pop())
    const shouldUseFirstResult =
      !isSearching &&
      (parsedUriParam.includes(parsedSearchValue) || isEditorialContent)
    const href = shouldUseFirstResult
      ? firstResult.href
      : `https://www.${selectedSite}.com/browse/${encodeURIComponent(
          searchValue
        )}`

    processAnalyticsSearchData({ href, index: 0, keyType })

    if (window) {
      window.location.href = href
    }
  }

  const search = () => {
    setIsSearching(true)
    const searchValueTrimmed = searchValue.trim()

    if (searchValueTrimmed.length < MINIMUM_SEARCH_VALUE_LENGTH) {
      setSearchResults([])
      return
    }

    if (!isSearchInitiated) {
      addSearchToDataLayer({ action: 'initiated' })
      setIsSearchInitiated(true)
    }

    let searchContext;
    if (selectedSite === THESAURUS_SITENAME) {
      searchContext = CONTEXT_THESAURUS_COM;
    } else {
      searchContext = CONTEXT_DICTIONARY_COM;
    }

    const endpointUrl = `${process.env.SAFE_SEARCH_ENDPOINT}?searchTerm=${searchValueTrimmed}&context=${searchContext}`;

    const updateSearchResults = async () => {
      const { count, data: results } = await fetch(endpointUrl)
        .then(data => data.json())
        .catch(error => {
          throw error
        })

      if (!results.length > 0) {
        clearSearchResults()
        return
      }

      setSearchResults(results)
      setSearchResultsCount(count)
      setIsSearching(false)
    }

    updateSearchResults()
  }

  useEffect(() => {
    if (!selectedResultValue) {
      search()
    }
  }, [searchValue])

  const displaySearchResults = useCallback(() => {
    const { definitions, featured, synonyms } = searchResults.reduce(
      (results, data) => {
        if (data.reference.source.name === SearchResultSources.DICTIONARY) {
          results.definitions.push(data)
        } else if (
          data.reference.source.name === SearchResultSources.THESAURUS
        ) {
          results.synonyms.push(data)
        } else {
          results.featured.push(data)
        }
        return results
      },
      {
        definitions: [],
        featured: [],
        synonyms: []
      }
    )

    const [firstResultsSection, secondResultsSection] =
      selectedSite === DICTIONARY_SITENAME
        ? [definitions, synonyms]
        : [synonyms, definitions]

    const firstSectionName =
      selectedSite === DICTIONARY_SITENAME ? 'Definitions' : 'Synonyms'

    const secondSectionName =
      selectedSite === DICTIONARY_SITENAME ? 'Synonyms' : 'Definitions'

    return (
      <>
        {[...firstResultsSection, ...secondResultsSection].length > 0 && (
          <div className={styles['definitions-and-synonyms']}>
            {firstResultsSection.length > 0 && (
              <div className={styles['definitions-or-synonyms']}>
                <SearchResultsSection
                  dictionaryUrl={dictionaryUrl}
                  listItems={firstResultsSection}
                  onClick={onClick}
                  searchValue={searchValue}
                  sectionName={firstSectionName}
                  selectedResultIndex={selectedResultIndex}
                  setSelectedResultIndex={setSelectedResultIndex}
                  startList={0}
                  thesaurusUrl={thesaurusUrl}
                  updateSelectedResultValue={updateSelectedResultValue}
                />
              </div>
            )}
            {secondResultsSection.length > 0 && (
              <div className={styles['definitions-or-synonyms']}>
                <SearchResultsSection
                  dictionaryUrl={dictionaryUrl}
                  listItems={secondResultsSection}
                  onClick={onClick}
                  searchValue={searchValue}
                  sectionName={secondSectionName}
                  selectedResultIndex={selectedResultIndex}
                  setSelectedResultIndex={setSelectedResultIndex}
                  startList={firstResultsSection.length}
                  thesaurusUrl={thesaurusUrl}
                  updateSelectedResultValue={updateSelectedResultValue}
                />
              </div>
            )}
          </div>
        )}
        {featured.length > 0 && (
          <SearchResultsSection
            dictionaryUrl={dictionaryUrl}
            listItems={featured}
            onClick={onClick}
            sectionName="Featured content"
            selectedResultIndex={selectedResultIndex}
            setSelectedResultIndex={setSelectedResultIndex}
            startList={totalResults - featured.length}
            thesaurusUrl={thesaurusUrl}
            updateSelectedResultValue={updateSelectedResultValue}
          />
        )}
      </>
    )
  }, [selectedResultIndex, searchResults])

  return (
    <form
      className={classNames}
      data-type="global-search"
      onKeyDown={onKeyDown}
      onSubmit={onSubmit}
    >
      <div className={styles['search-container']} ref={searchContainerRef}>
        <div className={styles['field-container']}>
          <input
            aria-autocomplete="list"
            autoCapitalize="none"
            autoComplete="off"
            autoCorrect="off"
            id="global-search"
            onChange={onChange}
            onFocus={search}
            placeholder={placeholderText}
            ref={searchInputRef}
            spellCheck={false}
            type="text"
            value={selectedResultValue || searchValue}
          />
          <Button className={styles['magnify-icon-button']} type="submit">
            <MagnifyingGlassIcon className={styles['magnify-icon']} />
          </Button>
        </div>
        {hasSearchResults && (
          <ul className={styles.results}>{displaySearchResults()}</ul>
        )}
      </div>
    </form>
  )
}

Search.propTypes = {
  className: string,
  dictionaryUrl: string,
  selectedSite: PROPTYPE_SITENAME.isRequired,
  thesaurusUrl: string
}

Search.defaultProps = {
  className: null,
  dictionaryUrl: DICTIONARY_URL,
  thesaurusUrl: THESAURUS_URL
}

export default withStyles(styles)(Search)
