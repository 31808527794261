import React, { useEffect } from 'react'
import { string } from 'prop-types'
import cx from 'classnames'

const SovrnAd = ({ className, slotName }) => {
  const classNames = cx(slotName, className)

  useEffect(() => {
    return () => {
      if (window.properDeleteSlot) {
        window.properDeleteSlot(slotName)
      }
    }
  }, [])

  return <div className={classNames} />
}

SovrnAd.propTypes = {
  className: string,
  slotName: string.isRequired
}

SovrnAd.defaultProps = {
  className: null
}

export default SovrnAd
