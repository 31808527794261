import React from 'react'
import { array, arrayOf, bool, object, oneOfType, string } from 'prop-types'
import withStyles from 'isomorphic-style-loader/withStyles'
import {
  FavoriteStarButton,
  IconButton,
  PronunciationAudio,
  PronunciationToggle,
  WotyBadge
} from '~composites'
import { A, Tooltip } from '~elements'
import useDictionaryUrl from '~hooks/useDictionaryUrl'
import useThesaurusUrl from '~hooks/useThesaurusUrl'
import compareIcon from '~public/images/icons/black-compare-synonym-icon.svg'
import hasHtmlTags from '~utils/hasHtmlTags'
import isArrayWithLength from '~utils/isArrayWithLength'
import parseHtml from '~utils/parseHtml'
import stripHtmlTags from '~utils/stripHtmlTags'
import styles from './DefinitionHeader.module.scss'

const MAX_SYNONYMS = 3

const DefinitionHeader = ({
  displayForm,
  homograph,
  pronunciation,
  showSynonymsHeader,
  slug,
  source,
  tunaInflections,
  tunaSlug,
  word,
  wordComparisons,
  wordVariants
}) => {
  const { compareWordsUrl } = useDictionaryUrl()
  const { browseUrl } = useThesaurusUrl()

  const isWOTY = word === 'hallucinate'
  const showWOTY = isWOTY && source === 'luna'
  const hasTunaInflections = isArrayWithLength(tunaInflections)
  const hasWordComparisons = isArrayWithLength(wordComparisons)

  let wordComparisonUrl = null
  let ipaOnlyBlock = null
  let spellOnlyBlock = null
  let pronunciationAudioBlock = null
  let pronunciationBlock = null
  let showSynonyms = null

  const isInitialHeader = showSynonymsHeader

  const header = isInitialHeader ? (
    <h1>{word}</h1>
  ) : (
    <span className={styles['additional-header']}>{word}</span>
  )

  const wordVariantsArray =
    typeof wordVariants === 'string' ? [wordVariants] : wordVariants
  const optionalSpelling = wordVariantsArray?.map(variant => {
    const strippedVariant = stripHtmlTags(variant)
    return (
      <div className={styles['optional-spelling']} key={strippedVariant}>
        <h3>{parseHtml(variant)}</h3>
      </div>
    )
  })

  let firstWord
  let secondWord = null

  if (hasWordComparisons) {
    const keyphrase = wordComparisons[0].keyphrase
    const words = keyphrase.split(' vs ')
    firstWord = words[0]
    secondWord = words[1]
    wordComparisonUrl = `${compareWordsUrl}/${firstWord}-vs-${secondWord}?root=${word}`
  }
  const tooltipContent = (
    <div className={styles['tooltip-content']}>
      See the most commonly confused word associated with {word}
    </div>
  )
  const tooltipAriaLabel = `This button will take you to ${firstWord} vs. ${secondWord} word comparison page`

  const handleWordComparison = () => {
    window.location.href = wordComparisonUrl
  }

  if (pronunciation) {
    const { audio, ipa, spell } = pronunciation
    const hasAudio = audio && !!audio['audio/mpeg']
    const hasPronunciation = ipa && spell
    const hasOnlyIpa = ipa && !hasPronunciation
    const hasOnlySpell = spell && !hasPronunciation
    const spellText = source === 'cultural' ? spell[0] : spell

    if (hasOnlyIpa) {
      const ipaContent = hasHtmlTags(ipa) ? parseHtml(ipa) : ipa
      ipaOnlyBlock = <p className={styles.ipa}>/ {ipaContent} /</p>
    }

    if (hasOnlySpell) {
      const spellContent = hasHtmlTags(spellText)
        ? parseHtml(spellText)
        : spellText
      spellOnlyBlock = <p className={styles.spell}>[ {spellContent} ]</p>
    }

    pronunciationBlock = hasPronunciation ? (
      <PronunciationToggle
        className={styles['pronunciation-toggle']}
        ipa={ipa}
        spelling={spellText}
      />
    ) : null

    pronunciationAudioBlock = hasAudio ? (
      <PronunciationAudio
        audioSrc={audio['audio/mpeg']}
        className={styles['pronunciation-audio']}
      />
    ) : null
  }

  const hasIcons = pronunciationAudioBlock || hasWordComparisons
  const hasSubheadingContent =
    ipaOnlyBlock ||
    spellOnlyBlock ||
    pronunciationBlock ||
    hasIcons ||
    showSynonymsHeader

  if (showSynonymsHeader && hasTunaInflections) {
    const synonymsList = tunaInflections.slice(0, MAX_SYNONYMS).map(synonym => {
      return (
        <A
          className={styles['synonyms-link']}
          href={`${browseUrl}/${synonym.tcomSlug}`}
          key={synonym.tcomSlug}
        >
          {synonym.displayForm}
        </A>
      )
    })

    showSynonyms = (
      <div className={styles.synonyms}>
        See synonyms for: <A href={`${browseUrl}/${tunaSlug}`}>{displayForm}</A>
        {synonymsList} on Thesaurus.com
      </div>
    )
  } else if (showSynonymsHeader && tunaSlug && !hasTunaInflections) {
    showSynonyms = (
      <A href={`${browseUrl}/${tunaSlug}`}>
        See synonyms for <i>{word}</i> on Thesaurus.com
      </A>
    )
  }

  return (
    <header className={styles.root}>
      {showWOTY && (
        <div className={styles.woty}>
          <WotyBadge />
        </div>
      )}
      <div className={styles.headword}>
        <div className={styles.word}>
          {header}
          {homograph && <sup>{homograph}</sup>}
        </div>
        {hasSubheadingContent && (
          <div className={styles.subheading}>
            {optionalSpelling}
            {spellOnlyBlock}
            {ipaOnlyBlock}
            {pronunciationBlock}
            <div className={styles['subheading-buttons']}>
              {hasIcons && (
                <div className={styles['icon-buttons']}>
                  {pronunciationAudioBlock}
                  {hasWordComparisons && (
                    <Tooltip
                      ariaLabel={tooltipAriaLabel}
                      content={tooltipContent}
                    >
                      <IconButton
                        aria-label={tooltipAriaLabel}
                        className={styles.compare}
                        onClick={handleWordComparison}
                        src={compareIcon}
                      />
                    </Tooltip>
                  )}
                </div>
              )}
              {showSynonymsHeader && slug && (
                <FavoriteStarButton
                  displayForm={displayForm}
                  showBackground={false}
                  slug={slug}
                />
              )}
            </div>
          </div>
        )}
      </div>
      {showSynonyms}
      <hr />
    </header>
  )
}

DefinitionHeader.propTypes = {
  displayForm: string,
  homograph: string,
  pronunciation: object,
  showSynonymsHeader: bool,
  slug: string,
  source: string,
  tunaInflections: arrayOf(object),
  tunaSlug: string,
  word: string.isRequired,
  wordComparisons: arrayOf(object),
  wordVariants: oneOfType([array, string])
}

DefinitionHeader.defaultProps = {
  displayForm: null,
  homograph: null,
  showSynonymsHeader: false,
  slug: null,
  source: null,
  tunaInflections: [],
  tunaSlug: null,
  wordComparisons: [],
  wordVariants: []
}

export default withStyles(styles)(DefinitionHeader)
