import { Auth } from '@aws-amplify/auth'
import Cookies from 'js-cookie'
import getRecurlySearchParams from '~utils/getRecurlySearchParams'
import isObjectWithProperties from '~utils/isObjectWithProperties'
import validateRedirect from './validateRedirect'

const USER_COOKIE_MATCH_NAME = 'idToken'

const config = {
  cookieStorage: {
    domain: process.env.SAFE_COGNITO_AUTH_COOKIE_DOMAIN,
    secure: process.env.SAFE_COGNITO_AUTH_COOKIE_USE_SECURE
  },
  oauth: {
    domain: process.env.SAFE_COGNITO_DOMAIN,
    redirectSignIn: process.env.SAFE_COGNITO_SIGN_IN_REDIRECT_URL,
    redirectSignOut:
      process.env.SAFE_COGNITO_SIGN_OUT_REDIRECT_URL ||
      'https://www.dictionary.com/auth?action=signout',
    responseType: 'code',
    scope: ['email', 'openid', 'profile', 'aws.cognito.signin.user.admin']
  },
  region: process.env.SAFE_COGNITO_REGION,
  userPoolId: process.env.SAFE_COGNITO_USER_POOL_ID,
  userPoolWebClientId: process.env.SAFE_COGNITO_CLIENT_ID
}
// Globally configures the `Auth` class to connect to our cognito instance
export const configureCognito = () => Auth.configure(config)

export const getIdToken = () => {
  const cookies = Cookies.get()
  if (!isObjectWithProperties(cookies)) return

  const tokenCookie = Object.entries(cookies).find(key =>
    /CognitoIdentityServiceProvider\.[\w.-]+\.idToken/.test(key)
  )
  return tokenCookie?.[1] || ''
}

export const getLastAuthUser = () => {
  const idToken = getIdToken()
  let userId = ''

  if (idToken) {
    const jwtEncodedPayload = idToken.split('.')[1]
    const decodedPayload = atob(jwtEncodedPayload)
    const jwtParsedPayload = JSON.parse(decodedPayload)
    userId = jwtParsedPayload?.sub
  }

  return userId
}

export const getUserTokenCookieName = () => {
  const cookies = Cookies.get()
  if (!isObjectWithProperties(cookies)) return

  const cookieNames = Object.keys(cookies)
  const userToken = cookieNames.find(key =>
    key.includes(USER_COOKIE_MATCH_NAME)
  )
  return userToken
}

export const clearCognitoCookies = () => {
  Object.keys(Cookies.get()).forEach(cookieKey => {
    if (cookieKey.includes('CognitoIdentityServiceProvider')) {
      Cookies.remove(cookieKey)
    }
  })
}

export const signIn = async (email, password) => {
  clearCognitoCookies()
  return await Auth.signIn(email, password)
}

export const signUp = async ({
  email,
  firstName,
  lastName,
  password,
  source,
  subscribe
}) => {
  await Auth.signUp({
    attributes: {
      'custom:source': source,
      'custom:subscribe': subscribe ? 'true' : 'false',
      family_name: lastName,
      given_name: firstName
    },
    password,
    username: email
  })

  return await Auth.signIn(email, password)
}

export const crossDomainSignIn = async ({
  query: { redirect: redirectOverride },
  dictionaryUrl,
  thesaurusUrl
}) => {
  const session = await Auth.currentSession()
  const idToken = session.getIdToken()
  const refreshToken = session.getRefreshToken()

  const TCOM_URL = process.env.SAFE_TCOM_AUTH_OVERRIDE_URL || thesaurusUrl

  let redirect = validateRedirect(redirectOverride)
    ? redirectOverride
    : dictionaryUrl

  if (
    redirectOverride?.includes('is-new-user') &&
    !redirect.includes(`is-new-user`)
  ) {
    redirect += `?${redirectOverride}`
  }

  if (
    redirect.includes('recurly.com/subscribe/') &&
    isObjectWithProperties(idToken.payload)
  ) {
    const {
      email,
      given_name: firstName,
      family_name: lastName,
      sub: userId
    } = idToken.payload
    const searchParams = getRecurlySearchParams(email, firstName, lastName)

    if (userId) {
      redirect = redirect.replace('account_code', userId)
      redirect += `/${searchParams}`
    }
  }

  const noun = idToken.payload.sub
  const verb = idToken.getJwtToken()
  const adj = refreshToken.getToken()

  const authPath = `/auth?noun=${noun}&verb=${verb}&adj=${adj}`
  const redirectPath = encodeURIComponent(redirect)

  window.location = `${TCOM_URL}${authPath}&redirect=${redirectPath}`
}

export const forgotPassword = async email => {
  return await Auth.forgotPassword(email)
}

export const resetPasswordSubmit = async ({ noun, password, verb: code }) => {
  let email
  try {
    email = atob(noun).toString('utf8')
  } catch (e) {
    throw Error('Invalid password reset code.')
  }
  return await Auth.forgotPasswordSubmit(email, code, password)
}

export const getUserEmail = () => {
  const cookies = Cookies.get()
  const userData = Object.entries(cookies).find(key =>
    // eslint-disable-next-line
    /CognitoIdentityServiceProvider\.[\w\.-]+\.userData/.test(key)
  )
  let res = ''
  if (userData) {
    const email = JSON.parse(userData[1])?.UserAttributes?.find(
      ({ Name }) => Name === 'email'
    ).Value
    res = email
  }
  return res
}

export const updatePassword = async ({ currentPassword, newPassword }) => {
  const user = await Auth.currentAuthenticatedUser()
  await Auth.changePassword(user, currentPassword, newPassword)
  return true
}

export const propagateMobileAppCookies = () => {
  if (global.window?.ReactNativeWebView) {
    try {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({ cookies: document.cookie, type: 'authentication' })
      )
    } catch (error) {
      throw new Error(error)
    }
  }
}
