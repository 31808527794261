// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.qvVRmBn60KryFORK8vwN h4{margin:0}.Oa95A7T2Gkipkpj2s9UC{background-color:var(--dictionaryblue);border-radius:100px;color:#fff;display:block;font-size:16px;font-weight:bold;margin-bottom:24px;padding:16px 40px;text-align:center;transition:all .25s ease-in-out 0s;width:100%}.Oa95A7T2Gkipkpj2s9UC:hover{transform:scale(1.05)}.Oa95A7T2Gkipkpj2s9UC:active{background-color:#00125d;transform:scale(1)}`, "",{"version":3,"sources":["webpack://./src/client/components/composites/PaymentModal/PaymentModal.module.scss"],"names":[],"mappings":"AACE,yBACE,QAAA,CAIJ,sBACE,sCAAA,CACA,mBAAA,CACA,UAAA,CACA,aAAA,CACA,cAAA,CACA,gBAAA,CACA,kBAAA,CACA,iBAAA,CACA,iBAAA,CACA,kCAAA,CACA,UAAA,CAEA,4BACE,qBAAA,CAGF,6BACE,wBAAA,CACA,kBAAA","sourcesContent":[".root {\n  h4 {\n    margin: 0;\n  }\n}\n\n.update-billing-button {\n  background-color: var(--dictionaryblue);\n  border-radius: 100px;\n  color: #fff;\n  display: block;\n  font-size: 16px;\n  font-weight: bold;\n  margin-bottom: 24px;\n  padding: 16px 40px;\n  text-align: center;\n  transition: all 0.25s ease-in-out 0s;\n  width: 100%;\n\n  &:hover {\n    transform: scale(1.05);\n  }\n\n  &:active {\n    background-color: #00125d;\n    transform: scale(1);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `qvVRmBn60KryFORK8vwN`,
	"update-billing-button": `Oa95A7T2Gkipkpj2s9UC`
};
module.exports = ___CSS_LOADER_EXPORT___;
