import React from 'react'
import { string } from 'prop-types'
import withStyles from 'isomorphic-style-loader/withStyles'
import styles from './DefinitionSourceCopyright.module.scss'

const year = new Date().getUTCFullYear()

const sourceCopyrightMap = {
  collins:
    'Collins English Dictionary - Complete & Unabridged 2012 Digital Edition \n© William Collins Sons & Co. Ltd. 1979, 1986 © HarperCollins \nPublishers 1998, 2000, 2003, 2005, 2006, 2007, 2009, 2012',
  cultural:
    'The New Dictionary of Cultural Literacy, Third Edition \nCopyright © 2005 by Houghton Mifflin Harcourt Publishing Company. Published by Houghton Mifflin Harcourt Publishing Company. All rights reserved.',
  idioms:
    'The American Heritage® Idioms Dictionary \n Copyright © 2002, 2001, 1995 by Houghton Mifflin Harcourt Publishing Company. Published by Houghton Mifflin Harcourt Publishing Company.',
  luna: `Dictionary.com Unabridged\nBased on the Random House Unabridged Dictionary, © Random House, Inc. ${year}`,
  science:
    'The American Heritage® Science Dictionary \n Copyright © 2011. Published by Houghton Mifflin Harcourt Publishing Company. All rights reserved.'
}

const DefinitionSourceCopyright = ({ source }) => {
  return (
    <div className={styles.root}>
      <p>{sourceCopyrightMap[source]}</p>
    </div>
  )
}

DefinitionSourceCopyright.propTypes = {
  source: string.isRequired
}

export default withStyles(styles)(DefinitionSourceCopyright)
