import React from 'react'
import { array, bool } from 'prop-types'
import withStyles from 'isomorphic-style-loader/withStyles'
import { useSelector } from 'react-redux'
import { Ad, DefinitionContent } from '~composites'
import { selectIsMobile } from '~store/userContext/selectors'
import { dcomResults } from '~utils/ads/adIds'
import stripHtmlTags from '~utils/stripHtmlTags'
import styles from './DefinitionList.module.scss'

const MAX_DEFINITIONS = 2

const DefinitionList = ({ definitions, isContinued: isContinuedList }) => {
  const isMobile = useSelector(selectIsMobile)
  const start = isContinuedList ? MAX_DEFINITIONS : 0
  const end = isContinuedList ? definitions.length : MAX_DEFINITIONS
  const topMobileAd = dcomResults.mobileDisplayTop

  const definitionList = definitions.slice(start, end).map(definition => {
    const { order, subdefinitions, type } = definition

    const subdefinitionList = subdefinitions?.map(subdefinitionEntry => {
      const filteredSlicedContent = stripHtmlTags(
        subdefinitionEntry.definition
      ).slice(0, 15)

      return (
        <li key={`subdefinition-${order}-${filteredSlicedContent}`}>
          <DefinitionContent definitionContent={subdefinitionEntry} />
        </li>
      )
    })

    return (
      <li key={`definition-${order}`}>
        <DefinitionContent definitionContent={definition} />
        {type === 'group' && subdefinitions && <ul>{subdefinitionList}</ul>}
        {isMobile && order === 1 && (
          <Ad adId={topMobileAd} className={styles.ad} />
        )}
      </li>
    )
  })

  return (
    <div className={styles.root}>
      <ol
        start={
          isContinuedList
            ? definitions[0].order + MAX_DEFINITIONS
            : definitions[0].order
        }
      >
        {definitionList}
      </ol>
    </div>
  )
}

DefinitionList.propTypes = {
  definitions: array.isRequired,
  isContinued: bool
}

DefinitionList.defaultProps = {
  isContinued: false
}

export default withStyles(styles)(DefinitionList)
