import Auth from '@aws-amplify/auth'
import { createSlice, current } from '@reduxjs/toolkit'
import {
  crossDomainSignIn,
  getUserTokenCookieName,
  propagateMobileAppCookies
} from '~utils/auth'
import {
  deleteUser,
  deleteWord,
  deleteWordList,
  fetchUserEmail,
  fetchUserProfile,
  fetchWordList,
  fetchWordLists,
  getAutocompleteResults,
  getEmailPreferences,
  postEmailPreferences,
  recoverPassword,
  resetPassword,
  saveToWordList,
  signInSSOUser,
  signInUser,
  signUpUserWithRedirect,
  updateBillingInfo,
  updateRoleName,
  updateUserEmail,
  updateUserPassword,
  updateUserProfile,
  upsertToWordList,
  upsertWordList,
  upsertWordListDefinition
} from './actions'
import initialState from './state.json'

export const accountsSlice = createSlice({
  extraReducers(builder) {
    builder
      .addCase(deleteWord.pending, state => {
        state.wordListStatus.status = 'loading'
        state.wordListStatus.type = 'deleteWord'
      })
      .addCase(deleteWord.fulfilled, (state, action) => {
        state.wordListStatus.status = 'succeeded'
        const deletedWordData = action.payload.data.deleteWord
        const currentState = current(state)
        const wordLists = currentState.wordLists.filter(
          wordList => wordList.shortId === deletedWordData.wordListId
        )
        if (wordLists.length) {
          const modifiedWordList = wordLists[0].words.filter(
            word => word.slug !== deletedWordData.slug
          )
          state.wordLists.forEach(wordList => {
            if (current(wordList).shortId === deletedWordData.wordListId) {
              wordList.words = modifiedWordList
            }
          })
        }
        if (currentState.wordList) {
          const modifiedWordList = currentState.wordList.words.filter(
            word => word.slug !== deletedWordData.slug
          )
          state.wordList.words = modifiedWordList
        }
      })
      .addCase(deleteWord.rejected, (state, action) => {
        state.wordListStatus = {
          error: action.error.message,
          status: 'failed'
        }
      })
      .addCase(fetchWordList.pending, state => {
        state.wordList.status = 'loading'
        state.wordListStatus.type = 'fetchWordList'
      })
      .addCase(fetchWordList.fulfilled, (state, action) => {
        state.wordList = action.payload?.data?.wordList
        state.wordList.status = 'success'
      })
      .addCase(fetchWordList.rejected, (state, action) => {
        state.wordList = {
          error: action.error.message,
          status: 'failed'
        }
      })
      .addCase(fetchWordLists.pending, state => {
        state.wordListStatus.status = 'loading'
      })
      .addCase(fetchWordLists.fulfilled, (state, action) => {
        state.wordListStatus.status = 'success'
        state.wordLists = action.payload?.data?.wordListsByUserId
      })
      .addCase(fetchWordLists.rejected, (state, action) => {
        state.wordListStatus = {
          error: action.error.message,
          status: 'failed'
        }
      })
      .addCase(saveToWordList.pending, state => {
        state.wordListStatus.status = 'loading'
      })
      .addCase(saveToWordList.fulfilled, (state, action) => {
        state.wordListStatus.status = 'succeeded'
        const savedWordData = action.payload.data.saveWord
        state.wordLists.forEach(wordList => {
          if (current(wordList).id === savedWordData.wordListId) {
            wordList.words.push(savedWordData.slug)
          }
        })
      })
      .addCase(saveToWordList.rejected, (state, action) => {
        state.wordListStatus = {
          error: action.error.message,
          status: 'failed'
        }
      })
      .addCase(updateBillingInfo.pending, state => {
        state.billingInfoStatus = 'loading'
      })
      .addCase(updateBillingInfo.fulfilled, state => {
        state.billingInfoStatus = 'succeeded'
      })
      .addCase(updateBillingInfo.rejected, state => {
        state.billingInfoStatus = 'failed'
      })
      .addCase(updateRoleName.pending, state => {
        state.roleNameStatus = 'loading'
      })
      .addCase(updateRoleName.fulfilled, state => {
        state.roleNameStatus = 'succeeded'
      })
      .addCase(updateRoleName.rejected, state => {
        state.roleNameStatus = 'failed'
      })
      .addCase(upsertWordListDefinition.pending, state => {
        state.wordListStatus.status = 'loading'
      })
      .addCase(upsertWordListDefinition.fulfilled, (state, action) => {
        state.wordListStatus.status = 'succeeded'
        state.wordListStatus.type = 'upsertWordListDefinition'
        const upsertWordData = action.payload.data.upsertWord
        const prevWords = [...current(state).wordList.words]
        const updatedWordIndex = prevWords.findIndex(
          ({ slug }) => slug === upsertWordData.slug
        )
        prevWords[updatedWordIndex] = {
          ...prevWords[updatedWordIndex],
          ...upsertWordData
        }
        state.wordList.words = prevWords
      })
      .addCase(upsertWordListDefinition.rejected, (state, action) => {
        state.wordListStatus = {
          error: action.error.message,
          status: 'failed'
        }
      })
      .addCase(upsertToWordList.pending, state => {
        state.wordListStatus.status = 'loading'
      })
      .addCase(upsertToWordList.fulfilled, (state, action) => {
        state.wordListStatus.status = 'succeeded'
        state.wordListStatus.type = 'upsertToWordList'
        const upsertWordData = action.payload.data.upsertWord
        state.wordLists.forEach(wordList => {
          if (current(wordList).id === upsertWordData.wordListId) {
            wordList.words.push({ slug: upsertWordData.slug })
          }
        })
        state.autocomplete = {}
      })
      .addCase(upsertToWordList.rejected, (state, action) => {
        state.wordListStatus = {
          error: action.error.message,
          status: 'failed'
        }
      })
      .addCase(upsertWordList.pending, state => {
        state.wordListStatus.status = 'loading'
        state.wordListStatus.type = 'upsertWordList'
      })
      .addCase(upsertWordList.fulfilled, (state, action) => {
        state.wordListStatus.status = 'succeeded'
        state.wordListStatus.type = 'upsertWordList'
        const upsertWordListData = action.payload.data.upsertWordList
        const indexOfWordList = state.wordLists.findIndex(
          wordList => wordList.shortId === upsertWordListData.shortId
        )
        // If wordList already exists, then replace with new data
        if (indexOfWordList !== -1) {
          state.wordLists = [
            ...state.wordLists.slice(0, indexOfWordList),
            upsertWordListData,
            ...state.wordLists.slice(indexOfWordList + 1)
          ]
          // Otherwise, add it
        } else {
          state.wordLists.push(upsertWordListData)
        }
      })
      .addCase(upsertWordList.rejected, (state, action) => {
        state.wordListStatus = {
          error: action.error.message,
          status: 'failed'
        }
      })
      .addCase(deleteWordList.pending, state => {
        state.wordListStatus.status = 'loading'
        state.wordListStatus.type = 'deleteWordList'
      })
      .addCase(deleteWordList.fulfilled, (state, action) => {
        state.wordListStatus.status = 'succeeded'
        const deleteWordListData = action.payload.data.deleteWordList
        state.wordLists = state.wordLists.filter(
          list => list.shortId !== deleteWordListData.shortId
        )
      })
      .addCase(deleteWordList.rejected, (state, action) => {
        state.wordListStatus = {
          error: action.error.message,
          status: 'failed'
        }
      })
      .addCase(signInUser.pending, state => {
        state.authStatus.status = 'loading'
      })
      .addCase(signInUser.fulfilled, state => {
        state.authStatus.status = 'succeeded'
        state.isLoggedIn = true
      })
      .addCase(signInUser.rejected, (state, action) => {
        state.authStatus = {
          error: action.error,
          status: 'failed'
        }
      })
      .addCase(signInSSOUser.pending, state => {
        state.authStatus.status = 'loading'
      })
      .addCase(signInSSOUser.fulfilled, state => {
        state.authStatus.status = 'succeeded'
        state.isLoggedIn = true
      })
      .addCase(signInSSOUser.rejected, (state, action) => {
        state.authStatus = {
          error: action.error,
          status: 'failed'
        }
      })
      .addCase(signUpUserWithRedirect.pending, state => {
        state.authStatus.status = 'loading'
      })
      .addCase(signUpUserWithRedirect.fulfilled, state => {
        state.authStatus.status = 'succeeded'
        state.isLoggedIn = true
      })
      .addCase(signUpUserWithRedirect.rejected, (state, action) => {
        state.authStatus = {
          error: action.error,
          status: 'failed'
        }
      })
      .addCase(getEmailPreferences.pending, state => {
        state.emailPreferences.status = 'loading'
      })
      .addCase(getEmailPreferences.fulfilled, (state, action) => {
        state.emailPreferences.status = 'succeeded'
        state.emailPreferences.emailAddress =
          action?.payload?.subscriber?.emailAddress
        state.emailPreferences.emailErrorCode = action?.payload?.code
        const prefs = action?.payload?.subscriber?.customFields?.map(
          e => e.value
        )
        state.emailPreferences.preferences = prefs
      })
      .addCase(getEmailPreferences.rejected, (state, action) => {
        state.emailPreferences = {
          error: action.error.message,
          status: 'failed'
        }
      })
      .addCase(postEmailPreferences.pending, state => {
        state.emailPreferences.status = 'loading'
      })
      .addCase(postEmailPreferences.fulfilled, state => {
        state.emailPreferences.status = 'succeeded'
      })
      .addCase(postEmailPreferences.rejected, (state, action) => {
        state.emailPreferences = {
          error: action.error.message,
          status: 'failed'
        }
      })
      .addCase(resetPassword.pending, state => {
        state.authStatus.status = 'loading'
      })
      .addCase(resetPassword.fulfilled, state => {
        state.authStatus.status = 'succeeded'
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.authStatus = {
          error: action.error,
          status: 'failed'
        }
      })
      .addCase(recoverPassword.pending, state => {
        state.authStatus.status = 'loading'
      })
      .addCase(recoverPassword.fulfilled, state => {
        state.authStatus.status = 'succeeded'
      })
      .addCase(recoverPassword.rejected, state => {
        state.authStatus.status = 'succeeded'
      })
      .addCase(fetchUserProfile.pending, state => {
        state.userProfile.status = 'loading'
      })
      .addCase(fetchUserProfile.fulfilled, (state, action) => {
        if (action.payload.errors) {
          state.userProfile.status = 'failed'
          state.userProfile.error.message = action.payload.errors[0]?.message
          state.userProfile.error.type = 'fetchUserProfile'
        } else {
          state.userProfile.status = 'succeeded'
          state.userProfile.data = action.payload.data?.getUserProfile
        }
      })
      .addCase(fetchUserProfile.rejected, (state, action) => {
        state.userProfile.status = 'failed'
        state.userProfile.error.message = action.error.message
        state.userProfile.error.type = 'fetchUserProfile'
      })
      .addCase(updateUserProfile.pending, state => {
        state.userProfile.status = 'loading'
        state.userProfile.success = {}
      })
      .addCase(updateUserProfile.fulfilled, (state, action) => {
        state.userProfile.status = 'succeeded'
        state.userProfile.data = {
          ...state.userProfile.data,
          ...action.payload
        }
        state.userProfile.success.type = 'updateProfile'
        state.userProfile.success.message =
          'Successfully updated first & last name.'
      })
      .addCase(updateUserProfile.rejected, (state, action) => {
        state.userProfile.status = 'failed'
        state.userProfile.error.message = action.error.message
      })
      .addCase(updateUserPassword.pending, state => {
        state.userProfile.status = 'loading'
        state.userProfile.success = {}
      })
      .addCase(updateUserPassword.fulfilled, state => {
        state.userProfile.status = 'succeeded'
        state.userProfile.success.type = 'updatePassword'
      })
      .addCase(updateUserPassword.rejected, (state, action) => {
        state.userProfile.status = 'failed'
        state.userProfile.error.message = action.error.message
      })
      .addCase(updateUserEmail.pending, state => {
        state.email.status = 'loading'
        state.userProfile.success = {}
      })
      .addCase(updateUserEmail.fulfilled, (state, action) => {
        state.email.status = 'succeeded'
        state.email.data = action.payload
        state.userProfile.success.type = 'updateProfile'
        state.userProfile.success.message = 'Successfully updated email.'
      })
      .addCase(updateUserEmail.rejected, (state, action) => {
        state.email.status = 'failed'
        state.email.error = action.error.message
      })
      .addCase(fetchUserEmail.pending, state => {
        state.email.status = 'loading'
      })
      .addCase(fetchUserEmail.fulfilled, (state, action) => {
        state.email.status = 'succeeded'
        state.email.data = action.payload.data?.me
      })
      .addCase(fetchUserEmail.rejected, (state, action) => {
        state.email.status = 'failed'
        state.email.error = action.error.message
      })
      .addCase(deleteUser.pending, state => {
        state.userProfile.status = 'loading'
      })
      .addCase(deleteUser.fulfilled, state => {
        state.userProfile.status = 'succeeded'
        state.userProfile.success.type = 'deleteUser'
        state.userProfile.data = null
      })
      .addCase(deleteUser.rejected, (state, action) => {
        state.userProfile.status = 'failed'
        state.userProfile.error.message = action.error.message
      })
      .addCase(getAutocompleteResults.pending, state => {
        state.autocomplete.status = 'loading'
      })
      .addCase(getAutocompleteResults.fulfilled, (state, action) => {
        state.autocomplete = {
          data: action.payload?.results,
          status: 'succeeded'
        }
      })
      .addCase(getAutocompleteResults.rejected, (state, action) => {
        state.autocomplete.status = 'failed'
        state.autocomplete.error.message = action.error.message
      })
  },
  initialState,
  name: 'accounts',
  reducers: {
    resetWordListStatus: state => {
      state.wordListStatus.status = null
      state.wordListStatus.type = null
    },
    setAppInitialized: state => {
      state.appInitialized = true
      if (getUserTokenCookieName()) {
        state.isLoggedIn = true
        Auth.currentSession()
        propagateMobileAppCookies()
      }
    },
    setIsGoogleSsoUser: (state, { payload }) => {
      state.isGoogleSsoUser = payload
    },
    setIsLoggedIn: (state, { payload }) => {
      state.isLoggedIn = payload
    },
    setSelectedEmailPreferences: (state, { payload }) => {
      state.emailPreferences.preferences = payload
    },
    setUserData: (state, { payload }) => {
      state.userData = payload
    },
    signOutUser: state => {
      state.isLoggedIn = false
    },
    syncCookiesRedirect: (_state, { payload }) => {
      propagateMobileAppCookies()
      const redirectQuery = payload.query.redirect || null
      const sessionStorageRedirect = sessionStorage.getItem('redirectURL')

      let queryOverride = {}
      if (redirectQuery && sessionStorageRedirect) {
        queryOverride = {
          query: {
            redirect: `${redirectQuery}${
              redirectQuery.includes('?') ? '&' : '?'
            }${sessionStorageRedirect}`
          }
        }
      } else if (!redirectQuery && sessionStorageRedirect) {
        queryOverride = { query: { redirect: sessionStorageRedirect } }
      }

      sessionStorage.removeItem('redirectURL')
      crossDomainSignIn({ ...payload, ...queryOverride })
    }
  }
})

export const actions = accountsSlice.actions

export default accountsSlice.reducer
