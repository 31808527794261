const getRecurlySearchParams = (email, firstName, lastName) => {
  const emailParam = email && `email=${encodeURIComponent(email)}`
  const firstNameParam =
    firstName && `first_name=${encodeURIComponent(firstName)}`
  const lastNameParam = lastName && `last_name=${encodeURIComponent(lastName)}`

  const searchParams = [emailParam, firstNameParam, lastNameParam]
    .filter(Boolean)
    .join('&')

  return searchParams ? `?${searchParams}` : ''
}

export default getRecurlySearchParams
