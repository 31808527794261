// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `a.XVQybJXLTZxlzjDXaNgj{color:var(--thesaurusorange);display:block;font-size:16px;font-weight:bold;position:relative;text-decoration:none}.I0EuzBKLg6JiKNZIeRmq{padding-left:40px}.q5BPuLdIi5AAYU55EwPk{color:var(--lightgraytext);content:"36";font-size:14px;font-weight:bold;height:32px;left:0;line-height:26px}`, "",{"version":3,"sources":["webpack://./src/client/components/composites/SupplementaryNotesCards/SupplementaryNotesCards.module.scss"],"names":[],"mappings":"AAAA,uBACE,4BAAA,CACA,aAAA,CACA,cAAA,CACA,gBAAA,CACA,iBAAA,CACA,oBAAA,CAGF,sBACE,iBAAA,CAGF,sBACE,0BAAA,CACA,YAAA,CACA,cAAA,CACA,gBAAA,CACA,WAAA,CACA,MAAA,CACA,gBAAA","sourcesContent":["a.thesaurus-link {\n  color: var(--thesaurusorange);\n  display: block;\n  font-size: 16px;\n  font-weight: bold;\n  position: relative;\n  text-decoration: none;\n}\n\n.list-item {\n  padding-left: 40px;\n}\n\n.def-number {\n  color: var(--lightgraytext);\n  content: '36';\n  font-size: 14px;\n  font-weight: bold;\n  height: 32px;\n  left: 0;\n  line-height: 26px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"thesaurus-link": `XVQybJXLTZxlzjDXaNgj`,
	"list-item": `I0EuzBKLg6JiKNZIeRmq`,
	"def-number": `q5BPuLdIi5AAYU55EwPk`
};
module.exports = ___CSS_LOADER_EXPORT___;
