// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.awJ0dXBZH1jB3iNI4owB{font-size:18px;line-height:1.5}.awJ0dXBZH1jB3iNI4owB ol{margin:0;padding-left:38px}.awJ0dXBZH1jB3iNI4owB ol p{margin:0}.awJ0dXBZH1jB3iNI4owB ol li{padding:0 0 8px 8px}.awJ0dXBZH1jB3iNI4owB li::marker{font-size:14px}.awJ0dXBZH1jB3iNI4owB ul{list-style:lower-alpha;padding-left:20px}.awJ0dXBZH1jB3iNI4owB ul p{margin:0}.RE7X0dh1iQeK_B7KMmBs{font-style:italic}.RE7X0dh1iQeK_B7KMmBs::after{content:" "}.rRVCUnK6A0wNCosbpIbM{margin-bottom:16px}div.WSlUs8AOkNtQ59OGPyeY{--gradient-height: 0}div.WSlUs8AOkNtQ59OGPyeY>section{height:0;padding:0}div.WSlUs8AOkNtQ59OGPyeY>button::before{height:0}`, "",{"version":3,"sources":["webpack://./src/client/components/composites/Definitions/Definitions.module.scss"],"names":[],"mappings":"AAAA,sBACE,cAAA,CACA,eAAA,CAEA,yBACE,QAAA,CACA,iBAAA,CAEA,2BACE,QAAA,CAIJ,4BACE,mBAAA,CAGF,iCACE,cAAA,CAGF,yBACE,sBAAA,CACA,iBAAA,CAEA,2BACE,QAAA,CAKN,sBACE,iBAAA,CAEA,6BACE,WAAA,CAIJ,sBACE,kBAAA,CAGF,yBACE,oBAAA,CAEA,iCACE,QAAA,CACA,SAAA,CAGF,wCACE,QAAA","sourcesContent":[".root {\n  font-size: 18px;\n  line-height: 1.5;\n\n  ol {\n    margin: 0;\n    padding-left: 38px;\n\n    p {\n      margin: 0;\n    }\n  }\n\n  ol li {\n    padding: 0 0 8px 8px;\n  }\n\n  li::marker {\n    font-size: 14px;\n  }\n\n  ul {\n    list-style: lower-alpha;\n    padding-left: 20px;\n\n    p {\n      margin: 0;\n    }\n  }\n}\n\n.pos {\n  font-style: italic;\n\n  &::after {\n    content: ' ';\n  }\n}\n\n.see-more-inline {\n  margin-bottom: 16px;\n}\n\ndiv.see-more-block {\n  --gradient-height: 0;\n\n  & > section {\n    height: 0;\n    padding: 0;\n  }\n\n  & > button::before {\n    height: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `awJ0dXBZH1jB3iNI4owB`,
	"pos": `RE7X0dh1iQeK_B7KMmBs`,
	"see-more-inline": `rRVCUnK6A0wNCosbpIbM`,
	"see-more-block": `WSlUs8AOkNtQ59OGPyeY`
};
module.exports = ___CSS_LOADER_EXPORT___;
