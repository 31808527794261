import React from 'react'
import { object } from 'prop-types'
import withStyles from 'isomorphic-style-loader/withStyles'
import parseHtml from '~utils/parseHtml'
import styles from './DefinitionContent.module.scss'

const DefinitionContent = ({
  definitionContent: { definition, predefinitionContent, postdefinitionContent }
}) => {
  return (
    <div className={styles.root} data-type="word-definition-content">
      <p>
        {predefinitionContent && parseHtml(`${predefinitionContent} `)}
        {definition && parseHtml(definition)}
        {postdefinitionContent && parseHtml(`: ${postdefinitionContent}`)}
      </p>
    </div>
  )
}

DefinitionContent.propTypes = {
  definitionContent: object.isRequired
}

export default withStyles(styles)(DefinitionContent)
