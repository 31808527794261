import React from 'react'
import { array, bool, number, object, string } from 'prop-types'
import withStyles from 'isomorphic-style-loader/withStyles'
import { CulturalDefinitionContent, DefinitionHeader } from '~composites'
import { ResultsCard } from '~elements'
import stripHtmlTags from '~utils/stripHtmlTags'
import styles from './CulturalDefinitionCard.module.scss'

const CulturalDefinitionCard = ({
  content,
  entry,
  homograph,
  pronunciation,
  showSynonymsHeader
}) => {
  const sanitizedHomograph = homograph ? homograph.toString() : null

  const culturalDefinitions = content.map((contentBlock, contentBlockIndex) => {
    const firstBlockContentText = contentBlock.content[0].content
    if (!firstBlockContentText) return null

    const contentId = `${stripHtmlTags(firstBlockContentText).substring(
      0,
      10
    )}-${contentBlockIndex}`

    const culturalDefinitionsContent = contentBlock.content.map(
      ({ content: contentText, type }, contentTextIndex) => {
        const definitionId = `${contentId}-${type}-${contentTextIndex}`

        return (
          <CulturalDefinitionContent
            content={contentText}
            key={definitionId}
            type={type}
          />
        )
      }
    )

    return <div key={contentId}>{culturalDefinitionsContent}</div>
  })

  return (
    <ResultsCard className={styles.card} data-type="cultural-definition-card">
      <DefinitionHeader
        homograph={sanitizedHomograph}
        pronunciation={pronunciation}
        showSynonymsHeader={showSynonymsHeader}
        source="cultural"
        word={entry}
      />
      {culturalDefinitions}
    </ResultsCard>
  )
}

CulturalDefinitionCard.propTypes = {
  content: array.isRequired,
  entry: string.isRequired,
  homograph: number,
  pronunciation: object,
  showSynonymsHeader: bool
}

CulturalDefinitionCard.defaultProps = {
  homograph: null,
  pronunciation: {},
  showSynonymsHeader: false
}

export default withStyles(styles)(CulturalDefinitionCard)
