import React, { useEffect } from 'react'
import { func, shape, string } from 'prop-types'
import withStyles from 'isomorphic-style-loader/withStyles'
import { useDispatch, useSelector, useStore } from 'react-redux'
import { Modal } from '~elements'
import { deleteWordList } from '~store/accounts/actions'
import { selectWordListStatus } from '~store/accounts/selectors'
import { actions as accountsActions } from '~store/accounts/slice'
import styles from './DeleteWordListModal.module.scss'

export const DeleteWordListModal = ({ modalState, wordListId }) => {
  const dispatch = useDispatch()
  const store = useStore()
  const { status, type } = useSelector(selectWordListStatus)

  const handleDeleteWordList = () => {
    store.dispatch(deleteWordList(wordListId))
  }

  useEffect(() => {
    if (status === 'succeeded' && type === 'deleteWordList') {
      modalState.hide()
      dispatch(accountsActions.resetWordListStatus())
    }
  }, [status, type])

  if (!modalState.open) return null

  return (
    <Modal className={styles.root} modalState={modalState}>
      <h3>Delete List</h3>
      <p>
        Are you sure you want to delete your list?{' '}
        <strong>Please note that your data will be lost.</strong>
      </p>
      <p>
        <strong>This action cannot be undone.</strong>
      </p>
      <div className={styles['button-container']}>
        <button
          className={styles['delete-button']}
          onClick={handleDeleteWordList}
          type="submit"
        >
          Delete List
        </button>
        <button className={styles['pill-button']} onClick={modalState.toggle}>
          Keep List
        </button>
      </div>
    </Modal>
  )
}

DeleteWordListModal.propTypes = {
  modalState: shape({
    toggle: func.isRequired
  }).isRequired,
  wordListId: string.isRequired
}

export default withStyles(styles)(DeleteWordListModal)
