import React from 'react'
import { array, oneOfType, string } from 'prop-types'
import withStyles from 'isomorphic-style-loader/withStyles'
import hasHtmlTags from '~utils/hasHtmlTags'
import parseHtml from '~utils/parseHtml'
import styles from './CulturalDefinitionContent.module.scss'

const CulturalDefinitionContent = ({ content, type }) => {
  let culturalContent
  if (hasHtmlTags(content)) {
    culturalContent = parseHtml(content)
  } else {
    culturalContent = content
  }

  const contentType =
    type === 'blockquote' ? (
      <blockquote>{culturalContent}</blockquote>
    ) : (
      <p>{culturalContent}</p>
    )

  return (
    <div className={styles.root} data-type="cultural-definition-content">
      {contentType}
    </div>
  )
}

CulturalDefinitionContent.propTypes = {
  content: oneOfType([array, string]).isRequired,
  type: string.isRequired
}

export default withStyles(styles)(CulturalDefinitionContent)
