// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sJj94mHlWBRhqhX589k1{background-color:#fff;padding:20px}.sJj94mHlWBRhqhX589k1 a{color:var(--dictionaryblue)}div.osE26kIvXx2B1cTEXZd3>section{height:0;padding:0}@media(min-width: 768px){.sJj94mHlWBRhqhX589k1{padding:35px 40px 24px}}`, "",{"version":3,"sources":["webpack://./src/client/components/composites/DefinitionCard/DefinitionCard.module.scss"],"names":[],"mappings":"AAAA,sBACE,qBAAA,CACA,YAAA,CAEA,wBACE,2BAAA,CAKF,iCACE,QAAA,CACA,SAAA,CAIJ,yBACE,sBACE,sBAAA,CAAA","sourcesContent":[".root {\n  background-color: #fff;\n  padding: 20px;\n\n  a {\n    color: var(--dictionaryblue);\n  }\n}\n\ndiv.see-more-definitions {\n  & > section {\n    height: 0;\n    padding: 0;\n  }\n}\n\n@media (min-width: 768px) {\n  .root {\n    padding: 35px 40px 24px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `sJj94mHlWBRhqhX589k1`,
	"see-more-definitions": `osE26kIvXx2B1cTEXZd3`
};
module.exports = ___CSS_LOADER_EXPORT___;
