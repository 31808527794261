import React, { useEffect, useRef, useState } from 'react'
import { bool, string } from 'prop-types'
import cx from 'classnames'
import withStyles from 'isomorphic-style-loader/withStyles'
import { useDispatch, useSelector, useStore } from 'react-redux'
import { FavoriteStarMenu } from '~composites'
import { ChevronDownIcon, StarIcon, StarOutlinedIcon } from '~elements'
import useDictionaryUrl from '~hooks/useDictionaryUrl'
import useMenuPositioning from '~hooks/useMenuPositioning'
import { fetchWordLists } from '~store/accounts/actions'
import {
  selectIsLoggedIn,
  selectIsSlugSavedInAWordList
} from '~store/accounts/selectors'
import { actions } from '~store/accounts/slice'
import { selectSiteName } from '~store/location/selectors'
import { DICTIONARY_SITENAME } from '~store/location/slice'
import { getUserTokenCookieName } from '~utils/auth'
import isObjectWithProperties from '~utils/isObjectWithProperties'
import styles from './FavoriteStarButton.module.scss'

export const loadData = ({ store }) => store.dispatch(fetchWordLists())

export const FavoriteStarButton = ({ displayForm, slug, showBackground }) => {
  const { signUpUrl } = useDictionaryUrl()
  const dispatch = useDispatch()
  const buttonRef = useRef()
  const menuRef = useRef()
  const { menuPositioning, positionMenu, setMenuPositioning } =
    useMenuPositioning(buttonRef, menuRef)
  const isLoggedIn = useSelector(selectIsLoggedIn)
  const siteName = useSelector(selectSiteName)
  const isSlugSavedInAWordList = useSelector(state =>
    selectIsSlugSavedInAWordList(state, slug)
  )

  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const store = useStore()

  const classNames = cx(styles.root, {
    [styles['no-background']]: !showBackground
  })
  const overlayClassNames = cx({
    [styles.active]: isMenuOpen
  })
  const favoriteStarMenuClassNames = cx(styles['favorite-star-menu'], {
    [styles.visible]: isObjectWithProperties(menuPositioning)
  })

  const { setIsLoggedIn } = actions
  const isDictionarySite = siteName === DICTIONARY_SITENAME
  const source = isDictionarySite ? 'dcom' : 'tcom'

  useEffect(() => {
    const userCookieName = getUserTokenCookieName()

    if (userCookieName) {
      dispatch(setIsLoggedIn(true))
      loadData({ store })
    }
  }, [isLoggedIn])

  useEffect(() => {
    if (isMenuOpen) {
      positionMenu()
    } else {
      setMenuPositioning({})
    }
  }, [isMenuOpen])

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen)
  }

  const handleAccountClick = () => {
    if (isLoggedIn) {
      toggleMenu()
    } else {
      window.location.href = signUpUrl
    }
  }

  return (
    <div data-type="favorite-star-button">
      <button
        className={classNames}
        data-analytics="aa-track"
        data-linklocation="body"
        data-linkmodule={isDictionarySite ? 'definition' : 'synonym'}
        data-linkname={`favorited:${slug}`}
        data-linktype="favorited"
        onClick={handleAccountClick}
        ref={buttonRef}
      >
        {isSlugSavedInAWordList ? <StarIcon /> : <StarOutlinedIcon />}
        {isLoggedIn && <ChevronDownIcon className={styles['dropdown-icon']} />}
      </button>
      {isMenuOpen && (
        <>
          <div
            aria-hidden="true"
            className={overlayClassNames}
            onClick={toggleMenu}
          ></div>
          <FavoriteStarMenu
            className={favoriteStarMenuClassNames}
            displayForm={displayForm}
            ref={menuRef}
            slug={slug}
            source={source}
            style={menuPositioning}
          />
        </>
      )}
    </div>
  )
}

FavoriteStarButton.propTypes = {
  displayForm: string.isRequired,
  showBackground: bool,
  slug: string.isRequired
}

FavoriteStarButton.defaultProps = {
  showBackground: true
}

export default withStyles(styles)(FavoriteStarButton)
