import React, { useEffect } from 'react'
import { bool, string } from 'prop-types'
import cx from 'classnames'
import withStyles from 'isomorphic-style-loader/withStyles'
import Cookies from 'js-cookie'
import decode from 'jwt-decode'
import { useDispatch } from 'react-redux'
import { DICTIONARY_URL, PATHNAME_SIGN_IN } from '~client/constants'
import { Button, UserCircleSolidIcon } from '~elements'
import { actions } from '~store/accounts/slice'
import styles from './AccountButton.module.scss'

const USER_COOKIE_MATCH_NAME = 'idToken'

export const AccountButton = ({ className, dictionaryUrl, isLoggedIn }) => {
  const dispatch = useDispatch()
  const { setIsLoggedIn, setUserData } = actions
  const signInUrl = `${dictionaryUrl}${PATHNAME_SIGN_IN}`
  const classNames = cx(styles.root, className)

  const forwardToLoginPage = () => {
    const currentUrl = window.location.href
    window.location.href = `${signInUrl}?redirect=${currentUrl}`
  }

  const resetUser = () => {
    dispatch(setIsLoggedIn(false))
    dispatch(setUserData(null))
  }

  useEffect(() => {
    const cookies = Cookies.get()
    const cookieNames = Object.keys(cookies)
    const userCookieName = cookieNames.find(key =>
      key.includes(USER_COOKIE_MATCH_NAME)
    )

    if (userCookieName) {
      const userCookie = cookies[userCookieName]
      const userData = decode(userCookie)

      dispatch(setUserData(userData))
      dispatch(setIsLoggedIn(true))
    } else {
      resetUser()
    }
  }, [isLoggedIn])

  const accountButton = isLoggedIn ? (
    <UserCircleSolidIcon className={styles.account} />
  ) : (
    <Button
      className={styles.login}
      data-analytics="aa-track"
      data-linkmodule="navigation"
      data-linkname="login"
      data-linktype="cta"
      data-linkurl={signInUrl}
      data-location="header"
      data-type="login-button"
      onClick={forwardToLoginPage}
    >
      Log In
    </Button>
  )

  return <div className={classNames}>{accountButton}</div>
}

AccountButton.propTypes = {
  className: string,
  dictionaryUrl: string,
  isLoggedIn: bool.isRequired
}

AccountButton.defaultProps = {
  className: null,
  dictionaryUrl: DICTIONARY_URL
}

export default withStyles(styles)(AccountButton)
