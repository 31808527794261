import React, { useEffect } from 'react'
import cx from 'classnames'
import withStyles from 'isomorphic-style-loader/withStyles'
import { useSelector, useStore } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { DICTIONARY_SITENAME, DICTIONARY_URL_CONTACT } from '~client/constants'
import { A } from '~elements'
import useDictionaryUrl from '~hooks/useDictionaryUrl'
import useThesaurusUrl from '~hooks/useThesaurusUrl'
import { fetchWordLists } from '~store/accounts/actions'
import { selectWordLists } from '~store/accounts/selectors'
import { selectSiteName } from '~store/location/selectors'
import styles from './AccountNav.module.scss'

export const loadData = ({ store }) => store.dispatch(fetchWordLists())

const AccountNav = () => {
  const { pathname } = useLocation()
  const siteName = useSelector(selectSiteName)
  const wordListsData = useSelector(selectWordLists)
  const store = useStore()
  const { dictionaryUrl } = useDictionaryUrl()
  const { thesaurusUrl } = useThesaurusUrl()

  useEffect(() => {
    loadData({ store })
  }, [])

  const siteUrl =
    siteName === DICTIONARY_SITENAME ? dictionaryUrl : thesaurusUrl

  const links = [
    {
      dataLinkName: 'word-lists',
      slug: '/account/word-lists',
      title: `Word Lists (${wordListsData.length})`
    },
    {
      dataLinkName: 'settings',
      slug: '/account',
      title: 'Settings'
    },
    {
      dataLinkName: 'help-center',
      slug: DICTIONARY_URL_CONTACT,
      title: 'Help Center'
    },
    {
      dataLinkName: 'sign-out',
      slug: `${siteUrl}/auth?action=signout`,
      title: 'Sign Out'
    }
  ]

  const navLinks = links.map(({ dataLinkName, slug, title }, index) => {
    const linkClassNames = cx({
      [styles.active]: slug === pathname
    })
    const position = index + 1
    return (
      <li className={linkClassNames} key={title}>
        <A
          data-analytics="aa-track"
          data-linklocation="left-rail"
          data-linkmodule="navigation"
          data-linkmodule_position={position}
          data-linkname={`account:${dataLinkName}`}
          data-linktype="link"
          data-linkurl={slug}
          href={slug}
        >
          {title}
        </A>
      </li>
    )
  })

  return (
    <nav className={styles.root}>
      <h5>My Account</h5>
      <ul>{navLinks}</ul>
    </nav>
  )
}

export default withStyles(styles)(AccountNav)
