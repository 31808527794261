import { Auth } from '@aws-amplify/auth'
import { createAsyncThunk } from '@reduxjs/toolkit'
import fetch from 'isomorphic-unfetch'
import { PATHNAME_SIGN_UP } from '~client/constants'
import { actions } from '~store/accounts/slice'
import {
  forgotPassword,
  getIdToken,
  resetPasswordSubmit,
  signIn,
  signUp,
  updatePassword
} from '~utils/auth'

const AUTOCOMPLETE_ENDPOINT = process.env.SAFE_DCOM_AUTOCOMPLETE_ENDPOINT
const GRAPHQL_ENDPOINT = process.env.SAFE_GRAPHQL_ENDPOINT

const parseGraphQLResponse = async response => {
  const data = await response.json()
  if (data.errors) {
    throw new Error(data.errors[0]?.message)
  }
  return data
}

const DELETE_USER = `
  mutation DeleteUser {
    deleteMe
  }
`

const DELETE_WORD_BY_SLUG = `
  mutation DeleteWordBySlug($slug: String!, $wordListId: ID) {
    deleteWord(slug: $slug, wordListShortId: $wordListId) {
      slug
      success
      wordListId
    }
  }
`

const DELETE_WORD_LIST = `
  mutation DeleteWordList($shortId: ID!) {
    deleteWordList(shortId: $shortId) {
      shortId
      success
    }
  }
`

const GET_USER_EMAIL_BY_USER_ID = `
  query UserEmailByUserId {
    me {
      email
    }
  }
`

const GET_USER_PROFILE_BY_USER_ID = `
  query UserProfileByUserId {
    getUserProfile {
      allowUpdatePassword
      firstName
      lastName
      primaryIdentitySource
      roleName
    }
  }
`

const GET_WORD_LIST = `
  query WordList($field: String!, $order: SortOrder!, $shortId: ID) {
    wordList(shortId: $shortId) {
      category
      color
      description
      id
      name
      shortId
      user {
        isApprovedEditor
      }
      userId
      words(sort: { field: $field, order: $order }) {
        createdAt
        definition
        definitionPath
        displayForm
        isLicensed
        isValidDcom
        isValidTcom
        pos
        pronunciationAudio
        slug
      }
    }
  }
`

const GET_WORD_LISTS_BY_USER_ID = `
  query WordListsByUserId {
    wordListsByUserId {
      category
      color
      createdAt
      description
      id
      name
      shortId
      words {
        slug
      }
    }
  }
`

const SAVE_WORD_BY_SLUG = `
  mutation SaveWordBySlug(
    $displayForm: String!
    $productSource: String!
    $slug: String!
  ) {
    saveWord(
      displayForm: $displayForm
      productSource: $productSource
      slug: $slug
    ) {
      displayForm
      slug
      wordListId
    }
  }
`

const UPDATE_BILLING_INFO = `
  mutation UpdateBillingInfo($token: String!) {
    updateBillingInfo(
      serviceName: "dictionary"
      token: $token
    )
  }
`

const UPDATE_ROLE_NAME = `
  mutation UpdateRoleName($roleName: String!) {
    updateRoleName(roleName: $roleName)
  }
`

const UPDATE_USER_EMAIL = `
  mutation UpdateUserEmail($email: String!) {
    updateUser(attributes: { email: $email })
  }
`

const UPDATE_USER_PASSWORD_CONFIRMATION = `
  mutation UpdateUserPasswordConfirmation {
    updateUserPasswordConfirmation
  }
`

const UPDATE_USER_PROFILE = `
  mutation UpdateUserProfile($firstName: String!, $lastName: String!) {
    updateUserProfile(
      attributes: { firstName: $firstName, lastName: $lastName }
    )
  }
`

const UPSERT_WORD = `
  mutation UpsertWord(
    $displayForm: String!
    $slug: String!
    $wordListShortId: ID!
  ) {
    upsertWord(
      displayForm: $displayForm
      productSource: "dcom"
      slug: $slug
      wordListShortId: $wordListShortId
    ) {
      slug
      wordListId
    }
  }
`

const UPSERT_WORD_DEFINITION = `
  mutation UpsertWordDefinition(
    $definition: String
    $definitionPath: String
    $displayForm: String!
    $pos: String
    $productSource: String
    $pronunciationAudio: String
    $slug: String!
    $wordListShortId: ID!
  ) {
    upsertWord(
      definition: $definition
      definitionPath: $definitionPath
      displayForm: $displayForm
      pos: $pos
      productSource: $productSource
      pronunciationAudio: $pronunciationAudio
      slug: $slug
      wordListShortId: $wordListShortId
    ) {
      definition
      definitionPath
      pos
      pronunciationAudio
      slug
      wordListId
    }
  }
`

const UPSERT_WORD_LIST = `
  mutation UpsertWordList(
    $category: String
    $color: String
    $description: String
    $id: ID
    $name: String
    $shortId: ID
  ) {
    upsertWordList(
      category: $category
      color: $color
      description: $description
      id: $id
      name: $name
      shortId: $shortId
    ) {
      category
      color
      createdAt
      description
      id
      name
      shortId
      userId
    }
  }
`

export const deleteWord = createAsyncThunk(
  'deleteWord',
  async ({ shortId, slug }) => {
    const endpointUrl = GRAPHQL_ENDPOINT
    const response = await fetch(endpointUrl, {
      body: JSON.stringify({
        query: DELETE_WORD_BY_SLUG,
        variables: {
          slug,
          wordListId: shortId
        }
      }),
      headers: {
        Authorization: getIdToken(),
        'Content-Type': 'application/json'
      },
      method: 'POST'
    })
      .then(parseGraphQLResponse)
      .catch(err => {
        throw err
      })

    return response
  }
)

export const updateBillingInfo = createAsyncThunk(
  'updateBillingInfo',
  async ({ token }) => {
    const endpointUrl = GRAPHQL_ENDPOINT
    const response = await fetch(endpointUrl, {
      body: JSON.stringify({
        query: UPDATE_BILLING_INFO,
        variables: {
          token: token.id
        }
      }),
      headers: {
        Authorization: getIdToken(),
        'Content-Type': 'application/json'
      },
      method: 'POST'
    })
      .then(parseGraphQLResponse)
      .catch(err => {
        throw err
      })

    return response
  }
)

export const updateRoleName = createAsyncThunk(
  'updateRoleName',
  async ({ roleName }) => {
    const endpointUrl = GRAPHQL_ENDPOINT
    const response = await fetch(endpointUrl, {
      body: JSON.stringify({
        query: UPDATE_ROLE_NAME,
        variables: {
          roleName
        }
      }),
      headers: {
        Authorization: getIdToken(),
        'Content-Type': 'application/json'
      },
      method: 'POST'
    })
      .then(parseGraphQLResponse)
      .catch(err => {
        throw err
      })

    return response
  }
)

export const fetchWordList = createAsyncThunk(
  'fetchWordList',
  async ({ noAuth, shortId }) => {
    const endpointUrl = GRAPHQL_ENDPOINT
    const response = await fetch(endpointUrl, {
      body: JSON.stringify({
        query: GET_WORD_LIST,
        variables: {
          field: 'createdAt',
          order: 'DESC',
          shortId
        }
      }),
      headers: {
        ...(!noAuth && { Authorization: getIdToken() }),
        'Content-Type': 'application/json'
      },
      method: 'POST'
    })
      .then(parseGraphQLResponse)
      .catch(err => {
        throw err
      })

    return response
  }
)

export const fetchWordLists = createAsyncThunk('fetchWordLists', async () => {
  const endpointUrl = GRAPHQL_ENDPOINT
  const response = await fetch(endpointUrl, {
    body: JSON.stringify({
      query: GET_WORD_LISTS_BY_USER_ID
    }),
    headers: {
      Authorization: getIdToken(),
      'Content-Type': 'application/json'
    },
    method: 'POST'
  })
    .then(parseGraphQLResponse)
    .catch(err => {
      throw err
    })

  return response
})

export const saveToWordList = createAsyncThunk(
  'saveToWordList',
  async ({ id, source, word }) => {
    const endpointUrl = GRAPHQL_ENDPOINT
    const response = await fetch(endpointUrl, {
      body: JSON.stringify({
        query: SAVE_WORD_BY_SLUG,
        variables: {
          productSource: source,
          slug: word,
          wordListId: id
        }
      }),
      headers: {
        Authorization: getIdToken(),
        'Content-Type': 'application/json'
      },
      method: 'POST'
    })
      .then(parseGraphQLResponse)
      .catch(err => {
        throw err
      })

    return response
  }
)

export const upsertToWordList = createAsyncThunk(
  'upsertWord',
  async ({ displayForm, shortId, slug, source }) => {
    const endpointUrl = GRAPHQL_ENDPOINT
    const response = await fetch(endpointUrl, {
      body: JSON.stringify({
        query: UPSERT_WORD,
        variables: {
          displayForm,
          productSource: source,
          slug,
          wordListShortId: shortId
        }
      }),
      headers: {
        Authorization: getIdToken(),
        'Content-Type': 'application/json'
      },
      method: 'POST'
    })
      .then(parseGraphQLResponse)
      .catch(err => {
        throw err
      })

    return response
  }
)

export const upsertWordList = createAsyncThunk(
  'upsertWordList',
  async ({ category, color, description, name, shortId }) => {
    const endpointUrl = GRAPHQL_ENDPOINT
    const response = await fetch(endpointUrl, {
      body: JSON.stringify({
        query: UPSERT_WORD_LIST,
        variables: {
          category,
          color,
          description,
          name,
          shortId
        }
      }),
      headers: {
        Authorization: getIdToken(),
        'Content-Type': 'application/json'
      },
      method: 'POST'
    })
      .then(parseGraphQLResponse)
      .catch(err => {
        throw err
      })

    return response
  }
)

export const deleteWordList = createAsyncThunk(
  'deleteWordList',
  async shortId => {
    const endpointUrl = GRAPHQL_ENDPOINT
    const response = await fetch(endpointUrl, {
      body: JSON.stringify({
        query: DELETE_WORD_LIST,
        variables: {
          shortId
        }
      }),
      headers: {
        Authorization: getIdToken(),
        'Content-Type': 'application/json'
      },
      method: 'POST'
    })
      .then(parseGraphQLResponse)
      .catch(err => {
        throw err
      })

    return response
  }
)

export const signInUser = createAsyncThunk(
  'signInUser',
  async ({ email, password }, { dispatch, getState }) => {
    const response = await signIn(email, password)
    dispatch(actions.syncCookiesRedirect(getState().location))
    dispatch(fetchUserProfile())
    return response.error || true
  }
)

export const signInSSOUser = createAsyncThunk(
  'accounts/signInSSOUser',
  async (_payload, { dispatch, getState }) => {
    dispatch(actions.syncCookiesRedirect(getState().location))
    dispatch(fetchUserProfile())
  }
)

export const signUpUserWithRedirect = createAsyncThunk(
  'signUpUserWithRedirect',
  async (formData, { dispatch, getState }) => {
    const { pathname, query: redirect } = getState().location

    if (pathname === PATHNAME_SIGN_UP) {
      const redirectParams = new URLSearchParams(redirect.search)
      redirectParams.set('is-new-user', true)
      sessionStorage.setItem('redirectURL', redirectParams)
    }

    const response = await signUp(formData)
    dispatch(actions.syncCookiesRedirect(getState().location))
    return response
  }
)

export const createEmailPreferencesUrl = email => {
  return `${process.env.SAFE_EMAIL_PREFERENCES_ENDPOINT}?email=${email}&includeTrackingPreference=true`
}

export const getEmailPreferences = createAsyncThunk(
  'getEmailPreferences',
  async email => {
    const endpointUrl = createEmailPreferencesUrl(email)
    const response = await fetch(endpointUrl)
      .then(data => data.json())
      .catch(err => {
        throw err
      })
    return response
  }
)

export const postEmailPreferences = createAsyncThunk(
  'postEmailPreferences',
  async preferencesConfig => {
    const response = await fetch(process.env.SAFE_EMAIL_PREFERENCES_ENDPOINT, {
      body: JSON.stringify(preferencesConfig),
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      method: 'POST'
    })
      .then(data => data.json())
      .catch(err => {
        throw err
      })
    return response
  }
)

export const resetPassword = createAsyncThunk(
  'accounts/resetPassword',
  async formData => {
    return await resetPasswordSubmit(formData)
  }
)

export const recoverPassword = createAsyncThunk(
  'accounts/forgotPassword',
  async ({ email }) => {
    return await forgotPassword(email)
  }
)

export const updateUserProfile = createAsyncThunk(
  'updateUserProfile',
  async ({ firstName, lastName }) => {
    const endpointUrl = GRAPHQL_ENDPOINT
    await fetch(endpointUrl, {
      body: JSON.stringify({
        query: UPDATE_USER_PROFILE,
        variables: {
          firstName,
          lastName
        }
      }),
      headers: {
        Authorization: getIdToken(),
        'Content-Type': 'application/json'
      },
      method: 'POST'
    })
      .then(parseGraphQLResponse)
      .catch(err => {
        throw err
      })
    return { firstName, lastName }
  }
)

export const fetchUserProfile = createAsyncThunk(
  'fetchUserProfile',
  async () => {
    const endpointUrl = GRAPHQL_ENDPOINT
    const response = await fetch(endpointUrl, {
      body: JSON.stringify({
        query: GET_USER_PROFILE_BY_USER_ID
      }),
      headers: {
        Authorization: getIdToken(),
        'Content-Type': 'application/json'
      },
      method: 'POST'
    })
      .then(parseGraphQLResponse)
      .catch(err => {
        throw err
      })
    return response
  }
)

export const updateUserEmail = createAsyncThunk(
  'updateUserEmail',
  async ({ email }) => {
    const endpointUrl = GRAPHQL_ENDPOINT
    await fetch(endpointUrl, {
      body: JSON.stringify({
        query: UPDATE_USER_EMAIL,
        variables: {
          email
        }
      }),
      headers: {
        Authorization: getIdToken(),
        'Content-Type': 'application/json'
      },
      method: 'POST'
    })
      .then(parseGraphQLResponse)
      .catch(err => {
        throw err
      })
    await Auth.currentAuthenticatedUser({ bypassCache: true })
    return { email }
  }
)

export const updateUserPassword = createAsyncThunk(
  'accounts/updateUserPassword',
  async ({ currentPassword, newPassword }, { dispatch, getState }) => {
    const passwordDidUpdate = await updatePassword({
      currentPassword,
      newPassword
    })
    if (passwordDidUpdate) {
      const endpointUrl = GRAPHQL_ENDPOINT
      await fetch(endpointUrl, {
        body: JSON.stringify({
          query: UPDATE_USER_PASSWORD_CONFIRMATION
        }),
        headers: {
          Authorization: getIdToken(),
          'Content-Type': 'application/json'
        },
        method: 'POST'
      })
    }

    dispatch(actions.syncCookiesRedirect(getState().location))
    return passwordDidUpdate
  }
)

export const fetchUserEmail = createAsyncThunk('fetchUserEmail', async () => {
  const endpointUrl = GRAPHQL_ENDPOINT
  const response = await fetch(endpointUrl, {
    body: JSON.stringify({
      query: GET_USER_EMAIL_BY_USER_ID
    }),
    headers: {
      Authorization: getIdToken(),
      'Content-Type': 'application/json'
    },
    method: 'POST'
  })
    .then(parseGraphQLResponse)
    .catch(err => {
      throw err
    })
  return response
})

export const deleteUser = createAsyncThunk('deleteUser', async () => {
  const endpointUrl = GRAPHQL_ENDPOINT
  const response = await fetch(endpointUrl, {
    body: JSON.stringify({
      query: DELETE_USER
    }),
    headers: {
      Authorization: getIdToken(),
      'Content-Type': 'application/json'
    },
    method: 'POST'
  })
    .then(parseGraphQLResponse)
    .catch(err => {
      throw err
    })
  return response
})

export const getAutocompleteResults = createAsyncThunk(
  'getAutocompleteResults',
  async word => {
    const endpointUrl = `${AUTOCOMPLETE_ENDPOINT}/${encodeURIComponent(word)}`
    const response = await fetch(endpointUrl)
      .then(data => data.json())
      .catch(err => {
        throw err
      })
    return response
  }
)

export const upsertWordListDefinition = createAsyncThunk(
  'upsertWordListDefinition',
  async ({
    wordListShortId,
    slug,
    displayForm,
    definition,
    definitionPath,
    pos,
    pronunciationAudio
  }) => {
    const endpointUrl = GRAPHQL_ENDPOINT
    const response = await fetch(endpointUrl, {
      body: JSON.stringify({
        query: UPSERT_WORD_DEFINITION,
        variables: {
          definition,
          definitionPath,
          displayForm,
          pos,
          pronunciationAudio,
          slug,
          wordListShortId
        }
      }),
      headers: {
        Authorization: getIdToken(),
        'Content-Type': 'application/json'
      },
      method: 'POST'
    })
      .then(parseGraphQLResponse)
      .catch(err => {
        throw err
      })

    return response
  }
)
