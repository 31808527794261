const PASSWORD_MIN_CHAR_LENGTH = 8

export const passwordDefaultValidationText = `Use at least ${PASSWORD_MIN_CHAR_LENGTH} characters. Include an uppercase letter, a
lowercase letter, a number, and a special character.`

const minLengthRegex = new RegExp(`.{${PASSWORD_MIN_CHAR_LENGTH},}`)
const upperCaseLetterRegex = /[A-Z]/
const lowerCaseLetterRegex = /[a-z]/
const digitRegex = /\d/
const specialCharsRegex = /[\^$*.[\]{}()?"!@#%&/\\,><':;|_~`]/

const regexes = [
  {
    message: `Please include at least ${PASSWORD_MIN_CHAR_LENGTH} characters.`,
    pattern: minLengthRegex
  },
  {
    message: 'Please include at least one upper case letter.',
    pattern: upperCaseLetterRegex
  },
  {
    message: 'Please include at least one lower case letter.',
    pattern: lowerCaseLetterRegex
  },
  { message: 'Please include at least one number.', pattern: digitRegex },
  {
    message:
      'Please include at least one special character from the following: ^ $ * . [ ] { } ( ) ? " ! @ # % & / \\ , > < \' : ; | _ ~ `',
    pattern: specialCharsRegex
  }
]

export const validatePassword = password =>
  regexes.every(({ pattern }) => {
    return pattern.test(password)
  })

export const createPasswordErrors = password => {
  const errors = regexes.reduce((acc, { pattern, message }) => {
    if (!pattern.test(password)) return [...acc, message]
    return acc
  }, [])
  return errors
}
