// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.QtP5JoIlxcF_eRFHhH5A{font-size:15px;line-height:22px;list-style-type:none;margin:0}.o7pSJedOLhFYo3wysIQ1{padding:0}`, "",{"version":3,"sources":["webpack://./src/client/components/composites/DefinitionContentList/DefinitionContentList.module.scss"],"names":[],"mappings":"AAAA,sBACE,cAAA,CACA,gBAAA,CACA,oBAAA,CACA,QAAA,CAGF,sBACE,SAAA","sourcesContent":[".root {\n  font-size: 15px;\n  line-height: 22px;\n  list-style-type: none;\n  margin: 0;\n}\n\n.reference-data {\n  padding: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `QtP5JoIlxcF_eRFHhH5A`,
	"reference-data": `o7pSJedOLhFYo3wysIQ1`
};
module.exports = ___CSS_LOADER_EXPORT___;
