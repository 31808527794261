import React from 'react'
import { array, string } from 'prop-types'
import withStyles from 'isomorphic-style-loader/withStyles'
import { DefinitionAdditionalContentCard } from '~composites'
import { A } from '~elements'
import useThesaurusUrl from '~hooks/useThesaurusUrl'
import parseHtml from '~utils/parseHtml'
import styles from './SupplementaryNotesCards.module.scss'

const SupplementaryNotesCards = ({ supplementaryNotes, word }) => {
  const { browseUrl } = useThesaurusUrl()

  const otherContent = supplementaryNotes.filter(
    notes => notes.type !== 'Antonyms' && notes.type !== 'Synonyms'
  )
  const synonymContent = supplementaryNotes.filter(
    notes => notes.type === 'Synonyms'
  )
  const antonymContent = supplementaryNotes.filter(
    notes => notes.type === 'Antonyms'
  )

  const contentList = contentItems => {
    return contentItems.map((contentItem, index) => {
      const listItems = contentItems[index + 1]
      if (index % 2 === 0 && listItems) {
        return (
          <tr key={contentItem}>
            <td className={styles['def-number']}>{parseHtml(contentItem)}</td>
            <td className={styles['list-item']}>{parseHtml(listItems)}</td>
          </tr>
        )
      }
      return null
    })
  }

  const supplementaryOtherCards = otherContent.map(({ content, type }) => {
    const lowerCasedType = type.toLowerCase()
    const stringifiedContent = Array.isArray(content)
      ? content.toString()
      : content

    return (
      <DefinitionAdditionalContentCard
        id={lowerCasedType}
        key={lowerCasedType}
        type={lowerCasedType}
        word={word}
      >
        {parseHtml(stringifiedContent)}
      </DefinitionAdditionalContentCard>
    )
  })

  const supplementaryThesaurusCards = content => {
    if (!content) return null

    const thesaurusCards = content.map(({ content, type }) => {
      const lowerCasedType = type.toLowerCase()
      const contentItems = content.split('.')
      const notesContent = contentList(contentItems)

      return (
        <DefinitionAdditionalContentCard
          id={lowerCasedType}
          key={lowerCasedType}
          type={lowerCasedType}
          word={word}
        >
          <table>
            <tbody>{notesContent}</tbody>
          </table>
          <A
            className={styles['thesaurus-link']}
            href={`${browseUrl}/${word}`}
            linkId="oowy0r"
          >
            See {lowerCasedType} for <i>{word}</i> on Thesaurus.com
          </A>
        </DefinitionAdditionalContentCard>
      )
    })
    return thesaurusCards
  }

  const supplementarySynonymsCards = supplementaryThesaurusCards(synonymContent)
  const supplementaryAntonymsCards = supplementaryThesaurusCards(antonymContent)

  return (
    <>
      {supplementaryOtherCards}
      {supplementarySynonymsCards}
      {supplementaryAntonymsCards}
    </>
  )
}

SupplementaryNotesCards.propTypes = {
  supplementaryNotes: array.isRequired,
  word: string.isRequired
}

export default withStyles(styles)(SupplementaryNotesCards)
