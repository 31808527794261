import React, { useEffect, useRef } from 'react'
import { string } from 'prop-types'
import cx from 'classnames'
import withStyles from 'isomorphic-style-loader/withStyles'
import {
  DICTIONARY_URL,
  DICTIONARY_URL_CONTACT,
  DICTIONARY_URL_TERMS,
  THESAURUS_URL
} from '~client/constants'
import { A } from '~elements'
import styles from './FooterCorpNav.module.scss'

const ABOUT_URL = `${DICTIONARY_URL}/e/about/`
const CAREERS_URL =
  'https://www.myrocketcareer.com/careers/?search=dictionary.com'

const dataAttributes = {
  'data-analytics': 'aa-track',
  'data-linklocation': 'footer',
  'data-linkmodule': 'sub-navigation',
  'data-linktype': 'cta',
  'data-type': 'pages-menu'
}

export const FooterCorpNav = ({ className }) => {
  const classNames = cx(styles.root, className)
  const oneTrustButtonRef = useRef()
  const OneTrust = typeof window !== 'undefined' && window.OneTrust
  let linkPosition = 1

  useEffect(() => {
    if (OneTrust) {
      const oneTrustButton = oneTrustButtonRef.current

      if (oneTrustButton) {
        oneTrustButton.onclick = function (e) {
          e.stopImmediatePropagation()
          OneTrust.ToggleInfoDisplay()
        }
      }
    }
  }, [])

  return (
    <nav className={classNames} data-type="footer-corp-nav">
      <menu>
        <li>
          <A
            {...dataAttributes}
            data-linkmodule_position={linkPosition++}
            data-linkname="about"
            data-linkurl={ABOUT_URL}
            href={ABOUT_URL}
          >
            About
          </A>
        </li>
        <li>
          <A
            {...dataAttributes}
            data-linkmodule_position={linkPosition++}
            data-linkname="careers"
            data-linkurl={CAREERS_URL}
            href={CAREERS_URL}
            target="_blank"
          >
            Careers
          </A>
        </li>
        <li>
          <A
            {...dataAttributes}
            data-linkmodule_position={linkPosition++}
            data-linkname="contact-us"
            data-linkurl={DICTIONARY_URL_CONTACT}
            href={DICTIONARY_URL_CONTACT}
          >
            Contact us
          </A>
        </li>
      </menu>
      <menu>
        <li>
          <A
            {...dataAttributes}
            data-linkmodule_position={linkPosition++}
            data-linkname="cookies-terms-privacy"
            data-linkurl={DICTIONARY_URL_TERMS}
            href={DICTIONARY_URL_TERMS}
          >
            Cookies, terms, &amp; privacy
          </A>
        </li>
        <li>
          <button
            {...dataAttributes}
            className="ot-sdk-show-settings"
            data-linkmodule_position={linkPosition++}
            data-linkname="do-not-sell-info"
            data-linkurl={THESAURUS_URL}
            id="ot-sdk-btn"
            ref={oneTrustButtonRef}
          >
            Do not sell my info
          </button>
        </li>
      </menu>
    </nav>
  )
}

FooterCorpNav.propTypes = {
  className: string
}

FooterCorpNav.defaultProps = {
  className: null
}

export default withStyles(styles)(FooterCorpNav)
