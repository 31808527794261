import React from 'react'
import { func, string } from 'prop-types'
import { PROPTYPE_CHILDREN } from '~client/constants'

const Form = ({ children, className, onSubmit }) => {
  const handleSubmit = event => {
    onSubmit(event)
  }

  return (
    <form className={className} noValidate onSubmit={handleSubmit}>
      <div>{children}</div>
    </form>
  )
}

Form.propTypes = {
  children: PROPTYPE_CHILDREN.isRequired,
  className: string,
  onSubmit: func.isRequired
}

Form.defaultProps = {
  className: null
}

export default Form
