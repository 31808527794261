import React, { useEffect } from 'react'
import { number, object } from 'prop-types'
import parseHtml from '~utils/parseHtml'

const EditorialEntriesBlock = ({ entry, index }) => {
  const hasEmbeddedTweets = !!entry.body?.match(/bundle-theme-twitter-embeds/)

  useEffect(() => {
    if (hasEmbeddedTweets) {
      const script = document.createElement('script')
      script.src =
        'https://www.dictionary.com/e/wp-content/themes/dictionary-com/js/build/bundle-theme-twitter-embeds.js'
      script.setAttribute('async', true)
      document.querySelector('body').appendChild(script)
    }
  }, [])

  return (
    <div key={`editors-note-${index + 1}`}>
      <div>{parseHtml(entry.body || entry.richAnswer)}</div>
    </div>
  )
}

EditorialEntriesBlock.propTypes = {
  entry: object.isRequired,
  index: number.isRequired
}

export default EditorialEntriesBlock
