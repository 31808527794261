import React from 'react'
import { arrayOf, bool, object, string } from 'prop-types'
import withStyles from 'isomorphic-style-loader/withStyles'
import { DefinitionHeader, Definitions, SeeMoreBlock } from '~composites'
import { ResultsCard } from '~elements'
import styles from './DefinitionCard.module.scss'

const MAX_DEFINITIONS = 3

const DefinitionCard = ({
  content,
  displayForm,
  showSynonymsHeader,
  hideWunderkindAd,
  slug,
  source,
  tunaInflections,
  tunaSlug,
  wordComparisons
}) => {
  const {
    entry,
    entryVariants,
    homograph,
    posBlocks = [],
    pronunciation
  } = content
  const wordVariants = entryVariants || []

  const filteredPosBlocks = posBlocks.filter(
    posBlock => !posBlock.pos.includes('Idioms')
  )

  const initialDefinitionList = filteredPosBlocks
    .slice(0, MAX_DEFINITIONS)
    .map(posBlock => {
      return (
        <Definitions
          key={`definition-${posBlock.pos}`}
          posBlock={posBlock}
          source={source}
        />
      )
    })

  const continueDefinitionList = filteredPosBlocks
    .slice(MAX_DEFINITIONS, filteredPosBlocks.length)
    .map(posBlock => {
      return (
        <Definitions
          key={`subdefinition-${posBlock.pos}`}
          posBlock={posBlock}
          source={source}
        />
      )
    })

  return (
    <ResultsCard
      className={styles.root}
      data-type="word-definition-card"
      hideWunderkindAd={hideWunderkindAd}
    >
      <DefinitionHeader
        displayForm={displayForm}
        homograph={homograph}
        pronunciation={pronunciation}
        showSynonymsHeader={showSynonymsHeader}
        slug={slug}
        source={source}
        tunaInflections={tunaInflections}
        tunaSlug={tunaSlug}
        word={entry}
        wordComparisons={wordComparisons}
        wordVariants={wordVariants}
      />
      {initialDefinitionList}
      {filteredPosBlocks.length > MAX_DEFINITIONS && (
        <SeeMoreBlock
          buttonClosedText={'See More Definitions'}
          buttonOpenText={'See Fewer Definitions'}
          className={styles['see-more-definitions']}
        >
          {continueDefinitionList}
        </SeeMoreBlock>
      )}
    </ResultsCard>
  )
}

DefinitionCard.propTypes = {
  content: object.isRequired,
  displayForm: string,
  hideWunderkindAd: bool,
  showSynonymsHeader: bool,
  slug: string,
  source: string,
  tunaInflections: arrayOf(object),
  tunaSlug: string,
  wordComparisons: arrayOf(object)
}

DefinitionCard.defaultProps = {
  displayForm: null,
  hideWunderkindAd: false,
  showSynonymsHeader: false,
  slug: null,
  source: null,
  tunaInflections: [],
  tunaSlug: null,
  wordComparisons: []
}

export default withStyles(styles)(DefinitionCard)
