// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.WSkNpmFhw1TEGvFLEeR9{color:var(--lightgray);font-size:14px;font-weight:normal;margin-bottom:4px;margin-top:20px;text-transform:uppercase}`, "",{"version":3,"sources":["webpack://./src/client/components/composites/DefinitionSectionHeader/DefinitionSectionHeader.module.scss"],"names":[],"mappings":"AAAA,sBACE,sBAAA,CACA,cAAA,CACA,kBAAA,CACA,iBAAA,CACA,eAAA,CACA,wBAAA","sourcesContent":[".root {\n  color: var(--lightgray);\n  font-size: 14px;\n  font-weight: normal;\n  margin-bottom: 4px;\n  margin-top: 20px;\n  text-transform: uppercase;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `WSkNpmFhw1TEGvFLEeR9`
};
module.exports = ___CSS_LOADER_EXPORT___;
