import { createSelector } from '@reduxjs/toolkit'
import { sortByKeyWithoutChangingCase } from '~utils/sortByKey'
import { transformDefinitionsData } from '~utils/transformDefinitionsData'
import { BROWSE_RESULTS_PER_PAGE, WORD_COMPARISON_PER_PAGE } from './slice'

const EMPTY_OBJECT = {}
const EMPTY_ARRAY = []

export const selectDcomTotalBrowsePages = state => {
  const totalResults = state.luna.dcomTotalResults
  return totalResults > 0
    ? Math.ceil(totalResults / BROWSE_RESULTS_PER_PAGE)
    : 0
}

export const selectDcomBrowseList = state => state.luna.dcomBrowseList

export const selectLunaComparisonList = state => state.luna.comparisonList

export const selectComparisonListTotalResults = state =>
  state.luna.comparisonListTotalResults

export const selectTotalComparisonPages = createSelector(
  selectComparisonListTotalResults,
  totalResults =>
    totalResults > 0 ? Math.ceil(totalResults / WORD_COMPARISON_PER_PAGE) : 0
)

export const selectCompareWords = state => state?.luna?.compareWords
export const selectExampleSentences = state => {
  return state?.luna?.exampleSentences?.data
}

export const selectResultsPageData = state =>
  state?.luna?.resultsData?.data || EMPTY_OBJECT

export const selectResultsPageDataStatus = state =>
  state?.luna?.resultsData?.status

export const selectCollinsResultsData = createSelector(
  selectResultsPageData,
  data => {
    const content = data?.content?.find(entry => entry.source === 'collins')
    const entries =
      data?.displayForm === 'ai'
        ? sortByKeyWithoutChangingCase(content?.entries, 'entry')
        : content?.entries
    const collinsContent = content?.entries
      ? { entries, source: 'collins' }
      : undefined

    return {
      content: collinsContent
    }
  }
)

export const selectCulturalResultsData = createSelector(
  selectResultsPageData,
  data => ({
    content: data?.content?.find(entry => entry.source === 'cultural'),
    displayForm: data?.displayForm,
    slug: data?.url,
    tunaInflections: data?.tunaInflections,
    tunaSlug: data?.tunaSlug,
    wordComparisons: data?.wordComparisons
  })
)

export const selectLunaResultsData = createSelector(
  selectResultsPageData,
  data => {
    const content = data?.content?.find(entry => entry.source === 'luna')
    const entries =
      data?.displayForm === 'ai'
        ? sortByKeyWithoutChangingCase(content?.entries, 'entry')
        : content?.entries
    const lunaContent = content?.entries
      ? { entries, source: 'luna' }
      : undefined

    return {
      content: lunaContent,
      displayForm: data?.displayForm,
      slug: data?.url,
      tunaInflections: data?.tunaInflections,
      tunaSlug: data?.tunaSlug,
      wordComparisons: data?.wordComparisons
    }
  }
)

export const selectScienceResultsData = createSelector(
  selectResultsPageData,
  data => ({
    content: data?.content?.find(entry => entry.source === 'science'),
    displayForm: data?.displayForm,
    slug: data?.url,
    tunaInflections: data?.tunaInflections,
    tunaSlug: data?.tunaSlug,
    wordComparisons: data?.wordComparisons
  })
)

export const selectResultsPageDefinition = state => {
  const definition =
    state?.luna?.resultsData?.data?.content?.[0]?.entries?.[0]?.posBlocks?.[0]
      ?.definitions?.[0]?.definition
  const subdefinition =
    state?.luna?.resultsData?.data?.content?.[0]?.entries?.[0]?.posBlocks?.[0]
      ?.definitions?.[0]?.subdefinitions?.[0]?.definition
  return definition || subdefinition || ''
}

export const selectResultsPageIdioms = createSelector(
  selectResultsPageData,
  data =>
    data?.content?.[0]?.entries?.[0]?.posBlocks?.find(entry =>
      entry.pos.match('Idioms')
    )
)

export const selectResultsPageOtherIdioms = createSelector(
  selectResultsPageData,
  data => ({
    content: data?.content?.find(entry => entry.source === 'idioms'),
    displayForm: data?.displayForm,
    slug: data?.url,
    tunaInflections: data?.tunaInflections,
    tunaSlug: data?.tunaSlug,
    wordComparisons: data?.wordComparisons
  })
)

export const selectWordsNearby = state =>
  state?.luna?.wordsNearby?.data || EMPTY_OBJECT

export const selectWordsRelated = state =>
  state?.luna?.wordsRelated?.data?.synonyms || EMPTY_ARRAY

export const selectSpellSuggestions = state =>
  state?.luna?.spellSuggestions?.data || EMPTY_OBJECT

export const selectTransformedDefinitionData = createSelector(
  selectResultsPageData,
  transformDefinitionsData
)
