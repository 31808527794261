import React from 'react'
import { object } from 'prop-types'
import withStyles from 'isomorphic-style-loader/withStyles'
import parseHtml from '~utils/parseHtml'
import styles from './IdiomList.module.scss'

const IdiomList = ({ idiom }) => {
  const { content, type } = idiom
  const idiomParagraph = type === 'paragraph' && <p>{parseHtml(content)}</p>
  const idiomsList =
    type === 'list' &&
    content.map(idiomContent => <li key={idiomContent}>{idiomContent}</li>)

  return (
    <>
      {idiomParagraph}
      {idiomsList && <ul className={styles.root}>{idiomsList}</ul>}
    </>
  )
}

IdiomList.propTypes = {
  idiom: object.isRequired
}

export default withStyles(styles)(IdiomList)
