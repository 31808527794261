// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vrJd3nEwRLrHn_aV8N0l{color:var(--lightgray);font-size:10px;line-height:20px;margin:-15px 0 20px;position:relative;text-transform:uppercase;white-space:pre-line}`, "",{"version":3,"sources":["webpack://./src/client/components/composites/DefinitionSourceCopyright/DefinitionSourceCopyright.module.scss"],"names":[],"mappings":"AAAA,sBACE,sBAAA,CACA,cAAA,CACA,gBAAA,CACA,mBAAA,CACA,iBAAA,CACA,wBAAA,CACA,oBAAA","sourcesContent":[".root {\n  color: var(--lightgray);\n  font-size: 10px;\n  line-height: 20px;\n  margin: -15px 0 20px;\n  position: relative;\n  text-transform: uppercase;\n  white-space: pre-line;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `vrJd3nEwRLrHn_aV8N0l`
};
module.exports = ___CSS_LOADER_EXPORT___;
