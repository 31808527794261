// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nDoiEfj3tBAe3reheyUF{line-height:1.5;list-style-type:none}.nDoiEfj3tBAe3reheyUF a{color:var(--dictionaryblue);display:block;font-size:14px;font-weight:bold;line-height:30px;overflow:hidden;text-decoration:none;text-overflow:ellipsis;white-space:nowrap}.nDoiEfj3tBAe3reheyUF a:hover{text-decoration:underline}`, "",{"version":3,"sources":["webpack://./src/client/components/elements/CompareMoreListItem/CompareMoreListItem.module.scss"],"names":[],"mappings":"AAAA,sBACE,eAAA,CACA,oBAAA,CAEA,wBACE,2BAAA,CACA,aAAA,CACA,cAAA,CACA,gBAAA,CACA,gBAAA,CACA,eAAA,CACA,oBAAA,CACA,sBAAA,CACA,kBAAA,CAEA,8BACE,yBAAA","sourcesContent":[".root {\n  line-height: 1.5;\n  list-style-type: none;\n\n  a {\n    color: var(--dictionaryblue);\n    display: block;\n    font-size: 14px;\n    font-weight: bold;\n    line-height: 30px;\n    overflow: hidden;\n    text-decoration: none;\n    text-overflow: ellipsis;\n    white-space: nowrap;\n\n    &:hover {\n      text-decoration: underline;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `nDoiEfj3tBAe3reheyUF`
};
module.exports = ___CSS_LOADER_EXPORT___;
