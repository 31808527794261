import React from 'react'
import { number, string } from 'prop-types'
import withStyles from 'isomorphic-style-loader/withStyles'
import styles from './DefinitionSectionHeader.module.scss'

const sourceSectionMap = {
  collins: 'British Dictionary definitions for',
  cultural: 'Cultural definitions for',
  idioms: 'Other Idioms and Phrases with',
  luna: 'Other definitions for',
  science: 'Scientific definitions for'
}

const DefinitionSectionHeader = ({ definitionCount, index, source, word }) => {
  const singleDefinition = definitionCount === 1
  const header =
    source === 'idioms' || singleDefinition
      ? `${sourceSectionMap[source]} ${word}`
      : `${sourceSectionMap[source]} ${word} (${index} of ${definitionCount})`

  return <h2 className={styles.root}>{header}</h2>
}

DefinitionSectionHeader.propTypes = {
  definitionCount: number,
  index: number,
  source: string.isRequired,
  word: string.isRequired
}

DefinitionSectionHeader.defaultProps = {
  definitionCount: null,
  index: null
}

export default withStyles(styles)(DefinitionSectionHeader)
