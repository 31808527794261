export const compareSynonyms = {
  mobileTop: {
    height: 50,
    slotName: 'thesaurus_content_320x50_noflex_1',
    width: 320
  }, // 'tcom_serp_mweb_atf_320x50_1'
  top: {
    height: 90,
    slotName: 'thesaurus_content_728x90_noflex_1',
    width: 728
  } // 'thesaurus_serp_atf_728x90'
}

export const compareWords = {
  bottom: {
    height: 90,
    slotName: 'dictionary_content_728x90_noflex_1',
    width: 728
  }, // 'dcom-serp-bottom-lb-728x90'
  mobileDisplayTop: {
    height: 250,
    slotName: 'dictionary_content_300x250_noflex_1',
    width: 300
  }, // 'dcomMobileRESULTSDisplayTopAd-300x250'
  mobileTop: {
    height: 50,
    slotName: 'dictionary_leaderboard_320x50_noflex_1',
    width: 320
  }, // 'dcomMobileRESULTSTopAd-320x50'
  right: {
    height: 250,
    slotName: 'dictionary_right_rail_300x250_noflex_1',
    width: 300
  }, // 'dcom-serp-a-top-300x250'
  top: {
    height: 90,
    slotName: 'dictionary_leaderboard_728x90_noflex_1',
    width: 728
  } // 'dcom-serp-lb-728x90'
}

export const dcomMisspelling = {
  msNrBottom: {
    height: 90,
    slotName: 'dictionary_content_728x90_noflex_1',
    width: 728
  }, // 'dcom-misspell-bottom-lb-728x90'
  msNrMobileBottom: {
    height: 250,
    slotName: 'dictionary_content_300x250_noflex_1',
    width: 300
  }, // 'dcomMobileMisspellBottomAd-300x250'
  msNrMobileTop: {
    height: 50,
    slotName: 'dictionary_leaderboard_320x50_noflex_1',
    width: 320
  }, // 'dcomMobileMisspellTop-320x50'
  msNrTop: {
    height: 90,
    slotName: 'dictionary_leaderboard_728x90_noflex_1',
    width: 728
  }, // 'dcom-misspell-lb-728x90'
  msNrTopRight: {
    height: 250,
    slotName: 'dictionary_right_rail_300x250_noflex_1',
    width: 300
  } // 'dcom-misspell-top-300x250'
}

export const dcomResults = {
  bottom: {
    height: 90,
    slotName: 'dictionary_content_728x90_noflex_1',
    width: 728
  }, // 'dcom-serp-bottom-lb-728x90'
  interstitial: {
    height: 320,
    slotName: 'dictionary_oop_interstitial_noflex_1',
    width: 480
  }, // 'dcom-serp-interstitial'
  mobileDisplayBottom: {
    height: 250,
    slotName: 'dictionary_content_300x250_noflex_3',
    width: 300
  }, // 'dcomMobileSERPDisplayBotAd-300x250'
  mobileDisplayMid: {
    height: 250,
    slotName: 'dictionary_content_300x250_noflex_2',
    width: 300
  }, // 'dcomMobileSERPDisplayMidAd-300x250'
  mobileDisplayTop: {
    height: 250,
    slotName: 'dictionary_content_300x250_noflex_1',
    width: 300
  }, // 'dcomMobileSERPDisplayTopAd-300x250'
  mobileTop: {
    height: 50,
    slotName: 'dictionary_leaderboard_320x50_noflex_1',
    width: 320
  }, // 'dcomMobileSERPTopAd-320x50'
  rightRails: [
    {
      height: 250,
      slotName: 'dictionary_right_rail_300x250_noflex_2',
      width: 300
    }, // 'dcom-serp-bot-300x250'
    {
      height: 250,
      slotName: 'dictionary_right_rail_300x250_noflex_3',
      width: 300
    }, // 'dcom-serp-bot-300x250_c'
    {
      height: 250,
      slotName: 'dictionary_right_rail_300x250_noflex_4',
      width: 300
    }, // 'dcom-serp-bot-300x250_d'
    {
      height: 250,
      slotName: 'dictionary_right_rail_300x250_noflex_5',
      width: 300
    } // 'dcom-serp-bot-300x250_e'
  ],
  top: {
    height: 90,
    slotName: 'dictionary_leaderboard_728x90_flex_1',
    width: 728
  }, // 'dcom-serp-lb-728x90'
  topRight: {
    height: 250,
    slotName: 'dictionary_right_rail_300x250_flex_1',
    width: 300
  } // 'dcom-serp-a-top-300x250'
}

export const dcomResultsRedesign = {
  ad1: {
    height: 90,
    slotName: 'dictionary_leaderboard_728x90_flex_1',
    width: 728
  },
  ad1Mobile: {
    height: 50,
    slotName: 'dictionary_sticky_320x50_noflex_1',
    width: 320
  },
  ad2: {
    height: 250,
    slotName: 'dictionary_right_rail_300x250_flex_1',
    width: 300
  },
  ad3: {
    height: 250,
    slotName: 'dictionary_left_rail_300x250_noflex_2',
    width: 300
  },
  ad5: {
    height: 250,
    slotName: 'dictionary_right_rail_300x250_noflex_1',
    width: 300
  },
  ad6: {
    height: 600,
    slotName: 'dictionary_right_rail_300x250_flex_2',
    width: 300
  },
  ad6Mobile: {
    height: 250,
    slotName: 'dictionary_content_300x250_noflex_2',
    width: 300
  },
  adMiddleMobile: {
    height: 250,
    slotName: 'dictionary_content_300x250_noflex_1',
    width: 300
  },
  interstitial: {
    height: 320,
    slotName: 'dictionary_oop_interstitial_noflex_1',
    width: 480
  }
}

export const editorial = {
  bottom: {
    height: 90,
    slotName: 'dictionary_content_728x90_noflex_1',
    width: 728
  }, // 'crb-ad-slot-bottom'
  mobileBottom: {
    height: 250,
    slotName: 'dictionary_content_300x250_noflex_2',
    width: 300
  }, // 'crb-ad-slot-bottom'
  mobileMiddle: {
    height: 250,
    slotName: 'dictionary_content_300x250_noflex_1',
    width: 300
  }, // 'crb-ad-slot-middle'
  mobileTop: {
    height: 50,
    slotName: 'dictionary_leaderboard_320x50_noflex_1',
    width: 320
  }, // 'crb-ad-slot-top'
  right: {
    height: 250,
    slotName: 'dictionary_right_rail_300x250_noflex_1',
    width: 300
  }, // 'crb-ad-slot-middle'
  top: {
    height: 90,
    slotName: 'dictionary_leaderboard_728x90_noflex_1',
    width: 728
  } // 'crb-ad-slot-top'
}

export const homepage = {
  dictionary: {
    ad1: {
      height: 600,
      slotName: 'dictionary_right_rail_300x250_flex_1',
      width: 300
    }, // 'dcomHomeTop-300x600_1'
    ad1Mobile: {
      height: 250,
      slotName: 'dictionary_content_300x250_noflex_1',
      width: 300
    }, // 'dcomMobileHomeTop-300x250_1'
    ad2: {
      height: 250,
      slotName: 'dictionary_left_rail_300x250_noflex_1',
      width: 300
    }, // 'dcomHomeTop-300x250_2'
    ad2Mobile: {
      height: 250,
      slotName: 'dictionary_content_300x250_noflex_2',
      width: 300
    }, // 'dcomMobileHomeBot-300x250_2'
    ad3: {
      height: 250,
      slotName: 'dictionary_content_300x250_noflex_1',
      width: 300
    }, // 'dcomHomeBot-300x250_3'
    ad3Mobile: {
      height: 250,
      slotName: 'dictionary_content_300x250_noflex_3',
      width: 300
    }, // 'dcomMobileHomeBot-300x250_3'
    ad4: {
      height: 600,
      slotName: 'dictionary_right_rail_300x250_flex_2',
      width: 300
    }, // 'dcomHomeBot-300x600_5'
    ad4Mobile: {
      height: 250,
      slotName: 'dictionary_content_300x250_noflex_4',
      width: 300
    }, // 'dcomMobileHomeBot-300x250_4'
    adDivider: {
      height: 250,
      slotName: 'dictionary_content_728x90_flex_1',
      width: 970
    }, // 'dcomHomeBot-970x250_4'
    adSticky: {
      height: 50,
      slotName: 'dictionary_sticky_320x50_noflex_1',
      width: 320
    } // 'dcomMobileHomeBot-320x50_5'
  },
  thesaurus: {
    ad1: {
      height: 600,
      slotName: 'thesaurus_right_rail_300x250_flex_1',
      width: 300
    }, // 'tcomHomeTop-300x600_1'
    ad1Mobile: {
      height: 250,
      slotName: 'thesaurus_content_300x250_noflex_1',
      width: 300
    }, // 'tcomMobileHomeTop-300x250_1'
    ad2: {
      height: 250,
      slotName: 'thesaurus_left_rail_300x250_noflex_1',
      width: 300
    }, // 'tcomHomeTop-300x250_2'
    ad2Mobile: {
      height: 250,
      slotName: 'thesaurus_content_300x250_noflex_2',
      width: 300
    }, // 'tcomMobileHomeBot-300x250_2'
    ad3: {
      height: 250,
      slotName: 'thesaurus_content_300x250_noflex_1',
      width: 300
    }, // 'tcomHomeBot-300x250_3'
    ad3Mobile: {
      height: 250,
      slotName: 'thesaurus_content_300x250_noflex_3',
      width: 300
    }, // 'tcomMobileHomeBot-300x250_3'
    ad4: {
      height: 250,
      slotName: 'thesaurus_right_rail_300x250_flex_2',
      width: 300
    }, // 'tcomHomeBot-300x600_5'
    ad4Mobile: {
      height: 250,
      slotName: 'thesaurus_content_300x250_noflex_4',
      width: 300
    }, // 'tcomMobileHomeBot-300x250_4'
    adDivider: {
      height: 250,
      slotName: 'thesaurus_content_728x90_flex_1',
      width: 970
    }, // 'tcomHomeBot-970x250_4'
    adSticky: {
      height: 50,
      slotName: 'thesaurus_sticky_320x50_noflex_1',
      width: 320
    } // 'tcomMobileHomeBot-320x50_5'
  }
}

export const learn = {
  middle: {
    height: 250,
    slotName: 'dictionary_right_rail_300x250_noflex_1',
    width: 300
  }, // 'crb-ad-slot-middle'
  mobileMiddle: {
    height: 250,
    slotName: 'dictionary_content_300x250_noflex_1',
    width: 300
  }, // 'crb-ad-slot-middle'
  mobileTop: {
    height: 50,
    slotName: 'dictionary_sticky_320x50_noflex_1',
    width: 320
  }, // 'crb-ad-slot-top'
  top: {
    height: 90,
    slotName: 'dictionary_sticky_728x90_noflex_1',
    width: 728
  } // 'crb-ad-slot-top'
}

export const listAz = {
  dcomTop: {
    height: 90,
    slotName: 'dictionary_sticky_728x90_noflex_1',
    width: 728
  }, // 'dcomHomeTop-728x90'
  mobileDcomTop: {
    height: 50,
    slotName: 'dictionary_sticky_320x50_noflex_1',
    width: 320
  }, // 'dcomMobileSERPTopAd-320x50'
  mobileTcomTop: {
    height: 50,
    slotName: 'thesaurus_sticky_320x50_noflex_1',
    width: 320
  }, // 'tcomMobileSERPTopAd-320x50'
  tcomTop: {
    height: 90,
    slotName: 'thesaurus_sticky_728x90_noflex_1',
    width: 728
  } // 'tcomHomeTop-728x90'
}

export const quizzes = {
  desktopQuiz: {
    height: 90,
    slotName: 'dictionary_sticky_728x90_noflex_1',
    width: 728
  }, // 'dcom_quiz_dkt_atf_728x90_pos1'
  mobileQuiz: {
    height: 50,
    slotName: 'dictionary_sticky_320x50_noflex_1',
    width: 320
  } // 'dcom_quiz_mweb_atf_320x50_pos1'
}

export const tcomMisspelling = {
  msNrBottom: {
    height: 90,
    slotName: 'thesaurus_content_728x90_noflex_1',
    width: 728
  }, // 'thesaurus_serp_728x90_p6'
  msNrMid: {
    height: 480,
    slotName: 'thesaurus_content_640x480_noflex_1',
    width: 640
  }, // 'OutstreamSlot1'
  msNrMobileBottom: {
    height: 250,
    slotName: 'thesaurus_content_300x250_noflex_1',
    width: 300
  }, // 'dmwMisspellThesBottom'
  msNrMobileTop: {
    height: 50,
    slotName: 'thesaurus_leaderboard_320x50_noflex_1',
    width: 320
  }, // 'dmwMisspellThesTop'
  msNrTop: {
    height: 90,
    slotName: 'thesaurus_leaderboard_728x90_flex_1',
    width: 728
  }, // 'thesaurus_serp_atf_728x90'
  msNrTopRight: {
    height: 250,
    slotName: 'thesaurus_right_rail_300x250_noflex_1',
    width: 300
  } // 'thesaurus_serp_atf_300x250'
}

export const tcomResults = {
  ad1: {
    height: 90,
    slotName: 'thesaurus_leaderboard_728x90_flex_1',
    width: 728
  }, // 'thesaurus_serp_atf_728x90'
  ad1Mobile: {
    height: 50,
    slotName: 'thesaurus_sticky_320x50_noflex_1',
    width: 320
  }, // 'tcom_serp_mweb_atf_320x50_1'
  ad2: {
    height: 250,
    slotName: 'thesaurus_right_rail_300x250_flex_1',
    width: 300
  }, // 'thesaurus_serp_atf_300x250'
  ad3: {
    height: 250,
    slotName: 'thesaurus_left_rail_300x250_noflex_2',
    width: 300
  }, // 'dcom-serp-bot-300x250_c'
  ad5: {
    height: 250,
    slotName: 'thesaurus_right_rail_300x250_noflex_1',
    width: 300
  }, // 'tcom_desktop_300x250_70'
  ad6: {
    height: 600,
    slotName: 'thesaurus_right_rail_300x250_flex_2',
    width: 300
  }, // 'tcom_serp_dkt_btf_300x600_4'
  ad6Mobile: {
    height: 250,
    slotName: 'thesaurus_content_300x250_noflex_2',
    width: 300
  }, // 'tcom_serp_mweb_mid_300x250_3'
  adMiddleMobile: {
    height: 250,
    slotName: 'thesaurus_content_300x250_noflex_1',
    width: 300
  }, // 'tcom_serp_mweb_atf_300x250_2'
  interstitial: {
    height: 320,
    slotName: 'thesaurus_oop_interstitial_noflex_1',
    width: 480
  } // 'tcom-serp-interstitial'
}

export const wordPuzzle = {
  middle: {
    height: 250,
    slotName: 'dictionary_right_rail_300x250_noflex_1',
    width: 300
  }, // 'crb-ad-slot-middle'
  mobileTop: {
    height: 50,
    slotName: 'dictionary_content_320x50_noflex_1',
    width: 320
  }, // 'crb-ad-slot-top',
  top: {
    height: 90,
    slotName: 'dictionary_sticky_728x90_noflex_1',
    width: 728
  } // 'crb-ad-slot-top'
}
