// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SqSNC1cP1YhY6Dsf1FMA{font-size:18px;line-height:1.5;width:initial}.LisEgN7_bYvDDuOldiKa{font-size:16px;font-weight:bold;margin:18px 0;text-transform:uppercase}`, "",{"version":3,"sources":["webpack://./src/client/components/composites/IdiomsAbout/IdiomsAbout.module.scss"],"names":[],"mappings":"AAAA,sBACE,cAAA,CACA,eAAA,CACA,aAAA,CAGF,sBACE,cAAA,CACA,gBAAA,CACA,aAAA,CACA,wBAAA","sourcesContent":[".root {\n  font-size: 18px;\n  line-height: 1.5;\n  width: initial;\n}\n\n.idioms {\n  font-size: 16px;\n  font-weight: bold;\n  margin: 18px 0;\n  text-transform: uppercase;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `SqSNC1cP1YhY6Dsf1FMA`,
	"idioms": `LisEgN7_bYvDDuOldiKa`
};
module.exports = ___CSS_LOADER_EXPORT___;
