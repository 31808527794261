import React, { useEffect, useRef } from 'react'
import { number, string } from 'prop-types'
import cx from 'classnames'
import withStyles from 'isomorphic-style-loader/withStyles'
import { PROPTYPE_CHILDREN } from '~client/constants'
import { DefinitionContentList } from '~composites'
import { ResultsCard } from '~elements'
import hasHtmlTags from '~utils/hasHtmlTags'
import parseHtml from '~utils/parseHtml'
import styles from './DefinitionAdditionalContentCard.module.scss'
import typeConfigMap from './typeConfigMap.json'

const DefinitionAdditionalContentCard = ({
  children,
  homograph,
  type,
  word,
  ...props
}) => {
  const {
    hasHomograph = false,
    headerText = `${type} For`,
    shouldAppendWord = true,
    shouldIndent = true,
    shouldUseDefinitionContentList = false
  } = typeConfigMap[type] || {}

  const classNames = cx({
    [styles['no-indent']]: !shouldIndent
  })
  const fullHeaderText =
    headerText && shouldAppendWord ? `${headerText} ${word}` : headerText

  let cardContent
  if (shouldUseDefinitionContentList) {
    cardContent = (
      <DefinitionContentList className={classNames} type={type}>
        {children}
      </DefinitionContentList>
    )
  } else if (hasHtmlTags(children)) {
    cardContent = parseHtml(children)
  } else {
    cardContent = children
  }

  const posRef = useRef()

  useEffect(() => {
    // Because this HTML comes from the API, we have to modify it after the fact
    const playerContainer = posRef.current?.querySelector('.audio-player')
    const audioElement = posRef.current?.querySelector('audio')
    if (playerContainer) {
      playerContainer.onclick = () => {
        audioElement.play()
      }
    }
  }, [posRef.current])

  return (
    <ResultsCard
      className={styles.root}
      data-type="word-additional-content-card"
      {...props}
    >
      {fullHeaderText && (
        <header>
          <div className={styles['heading-container']}>
            <h2>{fullHeaderText}</h2>
            {hasHomograph && <sup>{homograph}</sup>}
          </div>
        </header>
      )}
      <div ref={posRef}>{cardContent}</div>
    </ResultsCard>
  )
}

DefinitionAdditionalContentCard.propTypes = {
  children: PROPTYPE_CHILDREN.isRequired,
  homograph: number,
  type: string.isRequired,
  word: string.isRequired
}

DefinitionAdditionalContentCard.defaultProps = {
  homograph: null
}

export default withStyles(styles)(DefinitionAdditionalContentCard)
