import React, { useRef, useState } from 'react'
import { object } from 'prop-types'
import { useRecurly } from '@recurly/react-recurly'
import withStyles from 'isomorphic-style-loader/withStyles'
import { useStore } from 'react-redux'
import { Button, Modal, TextInput } from '~elements'
import { updateBillingInfo } from '~store/accounts/actions'
import isArrayWithLength from '~utils/isArrayWithLength'
import CreditCardInput from './CreditCardInput'
import styles from './PaymentModal.module.scss'

const creditCardFields = ['number', 'month', 'year', 'cvv']

const billingInputs = [
  {
    dataRecurly: 'first_name',
    label: 'First Name',
    name: 'firstName',
    placeholder: 'First name'
  },
  {
    dataRecurly: 'last_name',
    label: 'Last Name',
    name: 'lastName',
    placeholder: 'Last name'
  },
  {
    dataRecurly: 'address1',
    label: 'Street Address',
    name: 'streetAddress',
    placeholder: 'Street address'
  },
  {
    dataRecurly: 'postal_code',
    label: 'Zip Code',
    name: 'postalCode',
    placeholder: 'Zip code'
  }
]

const PaymentModal = ({ modalState }) => {
  const recurly = useRecurly()
  const recurlyForm = useRef(null)
  const [isValidBillingInputs, setIsValidBillingInputs] = useState(true)
  const [isValidCreditCard, setIsValidCreditCard] = useState(true)
  const store = useStore()

  if (!modalState.open) return null

  const updateBillingInformation = () => {
    if (!recurlyForm.current) return

    recurly.token(recurlyForm.current, async (errors, token) => {
      const errorFields = errors?.fields || []

      const hasBillingInputErrors = isArrayWithLength(
        errorFields.filter(field => !creditCardFields.includes(field))
      )
      const hasCreditCardErrors = isArrayWithLength(
        errorFields.filter(field => creditCardFields.includes(field))
      )

      setIsValidBillingInputs(!hasBillingInputErrors)
      setIsValidCreditCard(!hasCreditCardErrors)

      if (!hasBillingInputErrors && !hasCreditCardErrors && token) {
        store.dispatch(updateBillingInfo({ token }))
      }
    })
  }

  return (
    <Modal
      className={styles.root}
      data-type="payment-modal"
      heading="Update Payment Information"
      modalState={modalState}
    >
      <h4>Credit or Debit Card</h4>
      <form noValidate ref={recurlyForm}>
        <CreditCardInput
          isValid={isValidCreditCard}
          setIsValid={setIsValidCreditCard}
        />
        {billingInputs.map(({ dataRecurly, label, name, placeholder }) => (
          <TextInput
            data-recurly={dataRecurly}
            isValid={isValidBillingInputs}
            key={name}
            label={label}
            name={name}
            placeholder={placeholder}
          />
        ))}
        <Button
          className={styles['update-billing-button']}
          data-analytics="aa-track"
          data-linklocation="body"
          data-linkmodule="subscriptions"
          data-linkname="update-payment-method"
          data-linktype="cta"
          data-linkurl="/"
          onClick={updateBillingInformation}
        >
          Update Billing Information
        </Button>
      </form>
    </Modal>
  )
}

PaymentModal.propTypes = {
  modalState: object.isRequired
}

export default withStyles(styles)(PaymentModal)
