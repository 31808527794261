import { createApi } from '@reduxjs/toolkit/query/react'
import { graphqlRequestBaseQuery } from '@rtk-query/graphql-request-base-query'
import { gql } from 'graphql-tag'
import { getIdToken } from '~utils/auth'

export const SUBSCRIPTIONS_CACHE_KEY = 'subscriptions-page'

const GRAPHQL_ENDPOINT = process.env.SAFE_GRAPHQL_ENDPOINT

const CANCEL_SUBSCRIPTION = gql`
  mutation CancelSubscription($planCode: String!) {
    cancelSubscription(planCode: $planCode)
  }
`

const GET_USER_SUBSCRIPTION_INFO = gql`
  query UserSubscriptionInfo {
    getUserSubscriptionInfo {
      paymentMethodLastFour
      paymentMethodCardType
      serviceName
      subscriptions {
        expiresOn
        lastPayment
        nextInvoice
        planCode
        planName
        startDate
        status
        subtotal
      }
    }
  }
`

const REACTIVATE_SUBSCRIPTION = gql`
  mutation ReactivateSubscription($planCode: String!) {
    reactivateSubscription(planCode: $planCode)
  }
`

const UPDATE_SUBSCRIPTION = gql`
  mutation UpdateSubscription(
    $currentPlanCode: String!
    $newPlanCode: String!
  ) {
    updateSubscription(
      currentPlanCode: $currentPlanCode
      newPlanCode: $newPlanCode
    )
  }
`

export const subscriptionsApi = createApi({
  baseQuery: graphqlRequestBaseQuery({
    prepareHeaders: headers => {
      headers.set('Authorization', getIdToken())
      return headers
    },
    url: GRAPHQL_ENDPOINT
  }),
  endpoints: builder => ({
    cancelSubscription: builder.mutation({
      query: ({ planCode }) => ({
        document: CANCEL_SUBSCRIPTION,
        variables: {
          planCode
        }
      })
    }),
    getSubscriptions: builder.query({
      query: () => ({
        document: GET_USER_SUBSCRIPTION_INFO
      }),
      transformResponse: response => response.getUserSubscriptionInfo
    }),
    reactivateSubscription: builder.mutation({
      query: ({ planCode }) => ({
        document: REACTIVATE_SUBSCRIPTION,
        variables: {
          planCode
        }
      })
    }),
    updateSubscription: builder.mutation({
      query: ({ currentPlanCode, newPlanCode }) => ({
        document: UPDATE_SUBSCRIPTION,
        variables: {
          currentPlanCode,
          newPlanCode
        }
      })
    })
  }),
  reducerPath: 'subscriptionsApi'
})

export const {
  useGetSubscriptionsQuery,
  useCancelSubscriptionMutation,
  useReactivateSubscriptionMutation,
  useUpdateSubscriptionMutation
} = subscriptionsApi
