import React from 'react'
import { array, bool } from 'prop-types'
import withStyles from 'isomorphic-style-loader/withStyles'
import { DefinitionContent } from '~composites'
import stripHtmlTags from '~utils/stripHtmlTags'
import styles from './DefinitionSets.module.scss'

const MAX_DEFINITIONS = 2

const DefinitionSets = ({ definitions, isContinued }) => {
  const start = isContinued ? MAX_DEFINITIONS : 0
  const end = isContinued ? definitions.length : MAX_DEFINITIONS

  const definitionList = definitions
    .slice(start, end)
    .map((definitionContent, idx) => {
      const key = `definition-${idx}`
      const hasDefinition = definitionContent.definition
      const { subdefinitions } = definitionContent

      const subdefinitionList = subdefinitions?.map(subdefinitionEntry => {
        const filteredSlicedSubdefinition = stripHtmlTags(
          subdefinitionEntry.definition
        ).slice(0, 15)

        return (
          <li key={`subdefinition-${filteredSlicedSubdefinition}`}>
            <DefinitionContent definitionContent={subdefinitionEntry} />
          </li>
        )
      })

      return (
        <li key={key}>
          {hasDefinition && (
            <DefinitionContent definitionContent={definitionContent} />
          )}
          {subdefinitionList.length > 0 && <ul>{subdefinitionList}</ul>}
        </li>
      )
    })

  return (
    <div className={styles.root}>
      <ol>{definitionList}</ol>
    </div>
  )
}

DefinitionSets.propTypes = {
  definitions: array.isRequired,
  isContinued: bool
}

DefinitionSets.defaultProps = {
  isContinued: false
}

export default withStyles(styles)(DefinitionSets)
