import React from 'react'
import { string } from 'prop-types'
import cx from 'classnames'
import withStyles from 'isomorphic-style-loader/withStyles'
import { PROPTYPE_CHILDREN } from '~client/constants'
import parseHtml from '~utils/parseHtml'
import stripHtmlTags from '~utils/stripHtmlTags'
import styles from './DefinitionContentList.module.scss'

const DefinitionContentList = ({ className, children, type }) => {
  const isReferenceData = type === 'referenceData'
  const classNames = cx(styles.root, className, {
    [styles['reference-data']]: isReferenceData
  })

  const contentList = children.map(content => {
    const filteredSlicedContent = stripHtmlTags(content).slice(0, 15)

    return (
      <li key={`${type}-${filteredSlicedContent}`}>{parseHtml(content)}</li>
    )
  })

  return <ul className={classNames}>{contentList}</ul>
}

DefinitionContentList.propTypes = {
  children: PROPTYPE_CHILDREN.isRequired,
  className: string,
  type: string
}

DefinitionContentList.defaultProps = {
  className: null,
  type: null
}

export default withStyles(styles)(DefinitionContentList)
