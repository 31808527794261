import isObjectWithProperties from '~utils/isObjectWithProperties'

const sanitizeString = string =>
  string
    .replace(/&#(\d+);/g, (match, dec) => String.fromCharCode(dec))
    .replace(/(<sup>.*<\/sup>)|<([^>]*>)|((:|;).*$)/g, '')

const modelDefinitions = (definitions, path) =>
  definitions.reduce((acc, definition, index) => {
    if (definition.content) {
      acc.push({
        definition: sanitizeString(definition.content),
        definitionPath: `${path}.content[${index}]`
      })
      return acc
    }
    if (definition.type === 'group') {
      const groupName = sanitizeString(definition.predefinitionContent).replace(
        /\.$/,
        ''
      )
      const predefinition = groupName ? `${groupName} - ` : ''
      const subdefinitions = definition.subdefinitions.map(
        (subdefinition, subIndex) => ({
          definition: predefinition + sanitizeString(subdefinition.definition),
          definitionPath: `${path}.definitions[${index}].subdefinitions[${subIndex}]`
        })
      )
      return acc.concat(subdefinitions)
    }
    acc.push({
      definition: sanitizeString(definition.definition),
      definitionPath: `${path}.definitions[${index}]`
    })
    return acc
  }, [])

const modelContentBlocks = (contentBlocks, path) =>
  contentBlocks.map((contentBlock, index) => ({
    definitions: modelDefinitions(
      contentBlock.content,
      `${path}.contentBlocks[${index}]`
    )
  }))

const modelPosBlocks = (posBlocks, path) =>
  posBlocks.slice(0, 3).reduce((acc, posBlock, index) => {
    const pos = posBlock.pos
      ? sanitizeString(posBlock.pos).replace(/,$/, '')
      : ''
    if (pos !== 'Idioms') {
      acc.push({
        definitions: modelDefinitions(
          posBlock.definitions,
          `${path}.posBlocks[${index}]`
        ),
        pos
      })
    }
    return acc
  }, [])

export const transformDefinitionsData = data => {
  if (!isObjectWithProperties(data)) return {}
  return {
    displayForm: data.displayForm,
    entries: data.content[0].entries.map((entry, index) => {
      const pronunciationAudio =
        entry.pronunciation?.audio?.['audio/mpeg'] || null
      const subEntryBlocks = entry.posBlocks
        ? modelPosBlocks(entry.posBlocks, `content[0].entries[${index}]`)
        : modelContentBlocks(
            entry.contentBlocks,
            `content[0].entries[${index}]`
          )
      return {
        pronunciationAudio,
        subEntryBlocks
      }
    })
  }
}
