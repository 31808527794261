import React, { useState } from 'react'
import { object } from 'prop-types'
import { useDialogState } from 'ariakit'
import withStyles from 'isomorphic-style-loader/withStyles'
import {
  UpsertWordListModal,
  WordListKebabMenu,
  WordListKebabMenuOptions
} from '~composites'
import { A } from '~elements'
import pluralize from '~utils/pluralize'
import DeleteWordListModal from './DeleteWordListModal'
import styles from './WordListCard.module.scss'

export const WordListCard = ({ wordList }) => {
  const deleteWordListModalState = useDialogState({ animated: 150 })
  const upsertWordListModalState = useDialogState({ animated: 150 })
  const [isKebabMenuOpen, setIsKebabMenuOpen] = useState(false)

  const { category, color, name, shortId, words } = wordList
  const numberOfWords = words?.length || 0
  const wordCountText = `${numberOfWords} ${pluralize(numberOfWords, 'word')}`
  const wordListUrl = `/learn/word-lists/${category}/${shortId}`

  const openEditWordListModal = () => {
    setIsKebabMenuOpen(false)
    upsertWordListModalState.toggle()
  }

  const openDeleteWordListModal = () => {
    setIsKebabMenuOpen(false)
    deleteWordListModalState.toggle()
  }

  return (
    <>
      <div className={styles.root} data-type="word-list-card">
        <A
          className={styles['wordlist-card']}
          href={wordListUrl}
          style={{ backgroundColor: `#${color}` }}
        >
          <p className={styles['wordlist-title']}>{name}</p>
          <div className={styles['card-footer']}>
            <p className={styles['word-count']}>{wordCountText}</p>
          </div>
        </A>
        <WordListKebabMenu
          className={styles['kebab-menu']}
          isOpen={isKebabMenuOpen}
          setIsOpen={setIsKebabMenuOpen}
        >
          <WordListKebabMenuOptions
            category={category}
            openDeleteWordListModal={openDeleteWordListModal}
            openEditWordListModal={openEditWordListModal}
            setIsKebabMenuOpen={setIsKebabMenuOpen}
            shortId={shortId}
          />
        </WordListKebabMenu>
        <DeleteWordListModal
          modalState={deleteWordListModalState}
          wordListId={shortId}
        />
      </div>
      <UpsertWordListModal
        heading="Edit list details"
        modalState={upsertWordListModalState}
        wordList={wordList}
      />
    </>
  )
}

WordListCard.propTypes = {
  wordList: object.isRequired
}

export default withStyles(styles)(WordListCard)
