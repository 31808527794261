// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.uZkQFNevBX05THVv1Mmb{line-height:1.5}.uZkQFNevBX05THVv1Mmb .luna-example{color:var(--lightgray);display:block;font-size:16px}`, "",{"version":3,"sources":["webpack://./src/client/components/composites/DefinitionContent/DefinitionContent.module.scss"],"names":[],"mappings":"AAAA,sBACE,eAAA,CAEA,oCACE,sBAAA,CACA,aAAA,CACA,cAAA","sourcesContent":[".root {\n  line-height: 1.5;\n\n  :global(.luna-example) {\n    color: var(--lightgray);\n    display: block;\n    font-size: 16px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `uZkQFNevBX05THVv1Mmb`
};
module.exports = ___CSS_LOADER_EXPORT___;
