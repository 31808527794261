import React from 'react'
import { string } from 'prop-types'
import withStyles from 'isomorphic-style-loader/withStyles'
import { useStore } from 'react-redux'
import { A } from '~elements'
import { fetchCompareWords } from '~store/luna/actions'
import { LINKID_WORD_COMPARISON } from '~utils/analytics/linkIds'
import styles from './CompareMoreListItem.module.scss'

const CompareMoreListItem = ({ term1, term2 }) => {
  const store = useStore()
  const versus = `${term1}-vs-${term2}`

  const handleClick = e => {
    e.preventDefault()
    store.dispatch(fetchCompareWords({ versus }))
  }

  return (
    <li className={styles.root}>
      <A
        href={`/compare-words/${versus}`}
        linkId={LINKID_WORD_COMPARISON.keyphrase}
        onClick={handleClick}
      >
        {term1} vs. {term2}
      </A>
    </li>
  )
}

CompareMoreListItem.propTypes = {
  term1: string.isRequired,
  term2: string.isRequired
}

export default withStyles(styles)(CompareMoreListItem)
