import React, { forwardRef, useEffect, useState } from 'react'
import { string } from 'prop-types'
import cx from 'classnames'
import withStyles from 'isomorphic-style-loader/withStyles'
import { useSelector, useStore } from 'react-redux'
import { A, Button } from '~elements'
import useDictionaryUrl from '~hooks/useDictionaryUrl'
import {
  deleteWord,
  fetchWordLists,
  upsertToWordList,
  upsertWordList
} from '~store/accounts/actions'
import { selectWordLists } from '~store/accounts/selectors'
import styles from './FavoriteStarMenu.module.scss'

export const loadData = ({ store }) => store.dispatch(fetchWordLists())

const FavoriteStarMenu = forwardRef(
  ({ className, displayForm, source, slug, ...props }, ref) => {
    const { accountUrl } = useDictionaryUrl()
    const store = useStore()
    const wordListsData = useSelector(selectWordLists)
    const shouldShowFavoritesList = wordListsData.length === 0
    const [saveToFavorites, setSaveToFavorites] = useState(false)
    const hasOnlyFavoritesList =
      wordListsData.length === 1 &&
      Object.values(wordListsData[0]).includes('Favorites')

    const NEW_WORD_LIST_URL = `${accountUrl}/word-lists?action=create&slug=${slug}&displayForm=${displayForm}`

    const classNames = cx(styles.root, className)

    useEffect(() => {
      if (!shouldShowFavoritesList && hasOnlyFavoritesList && saveToFavorites) {
        const { shortId } = wordListsData[0]
        store.dispatch(upsertToWordList({ displayForm, shortId, slug, source }))
      }

      loadData({ store })
    }, [shouldShowFavoritesList])

    const handleWordListClick = (isPresentInList, shortId) => {
      if (isPresentInList) {
        store.dispatch(deleteWord({ shortId, slug }))
      } else {
        store.dispatch(upsertToWordList({ displayForm, shortId, slug, source }))
      }
    }

    const handleSaveToFavorites = () => {
      const favoritesList = {
        category: 'General',
        color: '00125D',
        description: '',
        name: 'Favorites'
      }
      store.dispatch(upsertWordList(favoritesList))
      setSaveToFavorites(true)
    }

    const savedLists = shouldShowFavoritesList ? (
      <li>
        <Button
          onClick={() => {
            handleSaveToFavorites()
          }}
        >
          Favorites
        </Button>
      </li>
    ) : (
      Object.values(wordListsData).map(lists => {
        const { name, shortId, words } = lists

        const isPresentInList = words?.some(
          savedWord => savedWord.slug === slug
        )

        return (
          <li key={shortId}>
            {isPresentInList && <span className={styles['saved-word']} />}
            <Button
              onClick={() => {
                handleWordListClick(isPresentInList, shortId)
              }}
            >
              {name}
            </Button>
          </li>
        )
      })
    )

    return (
      <div
        className={classNames}
        data-type="favorite-star-menu"
        ref={ref}
        {...props}
      >
        <ul>
          <li>
            <A href={NEW_WORD_LIST_URL}>New Word List</A>
          </li>
          {savedLists}
        </ul>
      </div>
    )
  }
)

FavoriteStarMenu.displayName = 'FavoriteStarMenu'

FavoriteStarMenu.propTypes = {
  className: string,
  displayForm: string.isRequired,
  slug: string.isRequired,
  source: string.isRequired
}

FavoriteStarMenu.defaultProps = {
  className: null
}

export default withStyles(styles)(FavoriteStarMenu)
