// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bwPdBhqbgWtKEFLb1lT9{color:var(--darkgraytext);font-size:16px;line-height:23px;margin-bottom:20px}.bwPdBhqbgWtKEFLb1lT9 h2{font-size:16px;margin:0 0 9px;text-transform:uppercase}.bwPdBhqbgWtKEFLb1lT9 header{margin:0 12px 4px 0}.bwPdBhqbgWtKEFLb1lT9 sup{font-size:10px;position:relative;top:-5px}.bwPdBhqbgWtKEFLb1lT9 a{color:var(--dictionaryblue);text-decoration:none}.lazGvCljpR8aelLyxQU3{display:flex}ul.htCdbPewOmK6cF2eJRmG{padding:0}@media(min-width: 768px){.bwPdBhqbgWtKEFLb1lT9 header{display:inline-block}}`, "",{"version":3,"sources":["webpack://./src/client/components/composites/DefinitionAdditionalContentCard/DefinitionAdditionalContentCard.module.scss"],"names":[],"mappings":"AAAA,sBACE,yBAAA,CACA,cAAA,CACA,gBAAA,CACA,kBAAA,CAEA,yBACE,cAAA,CACA,cAAA,CACA,wBAAA,CAGF,6BACE,mBAAA,CAGF,0BACE,cAAA,CACA,iBAAA,CACA,QAAA,CAGF,wBACE,2BAAA,CACA,oBAAA,CAIJ,sBACE,YAAA,CAGF,wBACE,SAAA,CAGF,yBACE,6BACE,oBAAA,CAAA","sourcesContent":[".root {\n  color: var(--darkgraytext);\n  font-size: 16px;\n  line-height: 23px;\n  margin-bottom: 20px;\n\n  h2 {\n    font-size: 16px;\n    margin: 0 0 9px;\n    text-transform: uppercase;\n  }\n\n  header {\n    margin: 0 12px 4px 0;\n  }\n\n  sup {\n    font-size: 10px;\n    position: relative;\n    top: -5px;\n  }\n\n  a {\n    color: var(--dictionaryblue);\n    text-decoration: none;\n  }\n}\n\n.heading-container {\n  display: flex;\n}\n\nul.no-indent {\n  padding: 0;\n}\n\n@media (min-width: 768px) {\n  .root header {\n    display: inline-block;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `bwPdBhqbgWtKEFLb1lT9`,
	"heading-container": `lazGvCljpR8aelLyxQU3`,
	"no-indent": `htCdbPewOmK6cF2eJRmG`
};
module.exports = ___CSS_LOADER_EXPORT___;
