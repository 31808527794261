import React, { useEffect, useState } from 'react'
import { func, object } from 'prop-types'
import cx from 'classnames'
import withStyles from 'isomorphic-style-loader/withStyles'
import { useDispatch, useSelector, useStore } from 'react-redux'
import {
  Button,
  CheckIcon,
  CircleExclamationIcon,
  CircleInfoIcon,
  Tooltip
} from '~elements'
import { upsertWordList } from '~store/accounts/actions'
import { selectWordListStatus } from '~store/accounts/selectors'
import { actions as accountsActions } from '~store/accounts/slice'
import styles from './WordListForm.module.scss'

const MAX_CHARACTER_COUNT_NAME = 50
const MAX_CHARACTER_COUNT_DESCRIPTION = 100
const MIN_CHARACTER_COUNT_NAME = 2

const categories = [
  'General',
  'College',
  'Common Core',
  'Fun',
  'K-12',
  'Literature',
  'Spelling',
  'Test Preparation',
  'Writing'
]

const colors = ['00125d', 'c20034', '860057', '004f3f', '005063', '0046be']

const WordListForm = ({ closeModal, wordList }) => {
  const dispatch = useDispatch()
  const store = useStore()
  const { status, type } = useSelector(selectWordListStatus)
  const [category, setCategory] = useState(wordList?.category || '')
  const [categoryError, setCategoryError] = useState('')
  const [description, setDescription] = useState(wordList?.description || '')
  const [descriptionError, setDescriptionError] = useState('')
  const [name, setName] = useState(wordList?.name || '')
  const [nameError, setNameError] = useState('')
  const [selectedColor, setSelectedColor] = useState(
    wordList?.color || colors[0]
  )
  const [showDropdown, setShowDropdown] = useState(false)

  useEffect(() => {
    if (status === 'succeeded' && type === 'upsertWordList') {
      closeModal()
      dispatch(accountsActions.resetWordListStatus())
    }
  }, [status, type])

  const categoryInputClassNames = cx(styles.category, {
    [styles.error]: !!categoryError
  })
  const colorLabelClassNames = cx(
    styles['input-container'],
    styles['color-label']
  )
  const descriptionClassNames = cx(styles['input-field'], styles.description)
  const nameInputClassNames = cx(styles['input-field'], {
    [styles.error]: !!nameError
  })

  const handleNameChange = ({ target: { value } }) => {
    if (value?.length > MAX_CHARACTER_COUNT_NAME) {
      setNameError(`Name cannot exceed ${MAX_CHARACTER_COUNT_NAME} characters.`)
    } else {
      setName(value)
      if (nameError) setNameError('')
    }
  }

  const handleDescriptionChange = ({ target: { value } }) => {
    if (value.length > MAX_CHARACTER_COUNT_DESCRIPTION) {
      setDescriptionError(
        `Your list description cannot exceed ${MAX_CHARACTER_COUNT_DESCRIPTION} characters.`
      )
    } else {
      setDescriptionError('')
      setDescription(value)
    }
  }

  const handleDropdown = category => {
    if (categoryError) setCategoryError('')
    setCategory(category)
    setShowDropdown(false)
  }

  const handleSubmit = e => {
    e.preventDefault()
    if (!name) {
      setNameError('Please give your list a name.')
    } else if (name.length < MIN_CHARACTER_COUNT_NAME) {
      setNameError('Please enter a longer list name.')
    }
    if (!category) {
      setCategoryError('Please select a category.')
    }

    if (!name || name.length < MIN_CHARACTER_COUNT_NAME || !category) return

    const wordListObject = {
      category,
      color: selectedColor,
      description,
      name
    }

    if (wordList) {
      wordListObject.shortId = wordList.shortId
    }

    store.dispatch(upsertWordList(wordListObject))
  }

  const tooltipContent = (
    <div className={styles['tooltip-content']}>
      These colors will help you differentiate your lists from one another at a
      glance!
    </div>
  )

  return (
    <form className={styles.root} noValidate onSubmit={handleSubmit}>
      <div className={styles['input-container']}>
        <label htmlFor="name">List Name</label>
        <div className={nameInputClassNames}>
          <input
            data-1p-ignore
            id="name"
            onBlur={handleNameChange}
            onChange={handleNameChange}
            placeholder='e.g. "Medieval Times"'
            required
            type="text"
            value={name}
          />
          <div>
            {name.length} / {MAX_CHARACTER_COUNT_NAME}
          </div>
        </div>
        {nameError && (
          <div className={styles['error-container']}>
            <CircleExclamationIcon />
            <p>{nameError}</p>
          </div>
        )}
      </div>
      <div className={styles['input-container']}>
        <label htmlFor="category">List Category</label>
        <div
          className={categoryInputClassNames}
          onClick={() => {
            setShowDropdown(!showDropdown)
          }}
          onKeyDown={({ key }) => {
            if (key === 'Enter') setShowDropdown(!showDropdown)
          }}
          tabIndex={0}
        >
          {category || 'Select a Category'}
          <div className={styles.icon} />
          {showDropdown && (
            <div className={styles.dropdown} tabIndex={0}>
              {categories.map(category => (
                <div
                  key={category}
                  onClick={() => {
                    handleDropdown(category)
                  }}
                  onKeyDown={({ key }) => {
                    if (key === 'Enter') handleDropdown(category)
                  }}
                  tabIndex={0}
                >
                  {category}
                </div>
              ))}
            </div>
          )}
        </div>
        <div>
          {categoryError && (
            <div className={styles['error-container']}>
              <CircleExclamationIcon />
              <p>{categoryError}</p>
            </div>
          )}
        </div>
      </div>
      <div className={styles['input-container']}>
        <label htmlFor="description">List Description</label>
        <div className={descriptionClassNames}>
          <textarea
            data-1p-ignore
            id="description"
            onBlur={handleDescriptionChange}
            onChange={handleDescriptionChange}
            placeholder="Write a brief description for this list."
            type="text"
            value={description}
          />
          <div className={styles['character-count']}>
            {description.length} / {MAX_CHARACTER_COUNT_DESCRIPTION}
          </div>
        </div>
        {descriptionError && (
          <div className={styles['error-container']}>
            <CircleExclamationIcon />
            <p>{descriptionError}</p>
          </div>
        )}
      </div>
      <div className={colorLabelClassNames}>
        <div className={styles['color-heading']}>
          <label>Pick a card color</label>
          <Tooltip ariaLabel="Pick a card color" content={tooltipContent}>
            <CircleInfoIcon />
          </Tooltip>
        </div>
        <div className={styles['color-container']}>
          {colors.map(color => (
            <label
              key={color}
              onClick={() => {
                setSelectedColor(color)
              }}
              style={{ backgroundColor: `#${color}` }}
            >
              <input type="checkbox" />
              <CheckIcon data-selected={color === selectedColor} />
            </label>
          ))}
        </div>
      </div>
      <Button className={styles['submit-button']} type="submit">
        {wordList ? 'Save Changes' : 'Create List'}
      </Button>
    </form>
  )
}

WordListForm.propTypes = {
  closeModal: func.isRequired,
  wordList: object
}

WordListForm.defaultProps = {
  wordList: null
}

export default withStyles(styles)(WordListForm)
